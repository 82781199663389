import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { loadUserRegion } from "../../../redux/action";
import "../../../styles/VhdBranch.scss";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { activeInactiveOptions } from "../../DropdownsData";
import { loginSubscriptionID } from "../../LoginRole";
import { travelizeAuth } from "../../HeaderAuthentication";
import { baseURL } from "../../BaseUrl";

export default function AddBranchModel(props) {
  let dispatch = useDispatch();

  const { userRegionResult } = useSelector((state) => state.data);
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryName, setCountryName] = useState({ value: null, label: "" });
  const [zoneName, setZoneName] = useState({ value: null, label: "" });
  const [stateName, setStateName] = useState({ value: null, label: "" });
  const [cityName, setCityName] = useState({ value: null, label: "" });

  const [branchData, setBranchdata] = useState({
    branch_code: "",
    point_of_Contact: "",
    country: "",
    zone: "",
    state: "",
    city: "",
    location: "",
    status: "active",
    name: "",
    mobile: "",
    email: "",
    subscriptionID: loginSubscriptionID,
  });

  const {
    branch_code,
    point_of_Contact,
    country,
    zone,
    state,
    city,
    location,
    status,
    name,
    mobile,
    email,
  } = branchData;

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  useLayoutEffect(() => {
    dispatch(loadUserRegion());
  }, []);

  useEffect(() => {
    if (Object.keys(userRegionResult).length > 0) {
      setCountryList(
        userRegionResult?.allCountry.map((data) => {
          return {
            value: data.countryID,
            label: data.countryName,
          };
        }) ?? []
      );
      setZoneList(
        userRegionResult?.allZones.map((data) => {
          return { value: data.zoneId, label: data.zoneName };
        }) ?? []
      );
      setStateList(
        userRegionResult?.allStates.map((data) => {
          return { value: data.stateId, label: data.stateName };
        }) ?? []
      );
      setCityList(
        userRegionResult?.allCities.map((data) => {
          return { value: data.cityId, label: data.cityName };
        }) ?? []
      );
    }
  }, [userRegionResult]);

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setBranchdata({ ...branchData, [name]: value });
  };

  const handleSelectChange = (data, type) => {
    setBranchdata({ ...branchData, [type]: data.label });
    switch (type) {
      case "country":
        setCountryName(data);
        break;
      case "zone":
        setZoneName(data);
        break;
      case "state":
        setStateName(data);
        break;
      case "city":
        setCityName(data);
        break;
      default:
    }
  };

  useEffect(() => {
    {
      props.branchID && getBranchDataByCode();
    }
  }, [props.branchID]);

  const getBranchDataByCode = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/VHD/GetALLBRANCH?Branch_code=${props.branchID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setBranchdata({ ...res.result[0] });
        } else {
          setBranchdata({ ...branchData });
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  // console.log("BRNACH-DATA:", branchData);

  useEffect(() => {
    if (country !== null && country !== "") {
      countryList.map((contry) => {
        if (country == contry.label) {
          setCountryName(contry);
        }
      });
    }
    if (zone !== null && zone !== "") {
      zoneList.map((zones) => {
        if (zone == zones.label) {
          setZoneName(zones);
        }
      });
    }
    if (state !== null && state !== "") {
      stateList.map((states) => {
        if (state == states.label) {
          setStateName(states);
        }
      });
    }
    if (city !== null && city !== "") {
      cityList.map((cities) => {
        if (city == cities.label) {
          setCityName(cities);
        }
      });
    }
  }, [country, zone, state, city]);

  const addBranchData = (e) => {
    e.preventDefault();
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(branchData),
    };
    fetch(`${baseURL}/api/VHD/AddBranch`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          props.fetchBranchDetails();
          closeModel();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const closeModel = () => {
    props.handleClose();
    props.setBranchID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={props.branchID ? "Edit Branch" : "Add Branch"}
        formSubTitle={
          props.branchID
            ? "Edit the branch details."
            : "Please add the branch details."
        }
        sidebarItems={props.branchID ? ["Edit Branch"] : ["Add Branch"]}
        childrens={
          <>
            <form onSubmit={addBranchData}>
              <div className="add-branch-to-vhd">
                <div className="add-branch-details">
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Branch Name"
                        className={`${
                          name
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          name="name"
                          className="form-control form add-input"
                          placeholder="Branch"
                          value={name}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>

                      {/* {fieldReq && name == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )} */}
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Branch Code"
                        className={`${
                          branch_code
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          name="branch_code"
                          className="form-control form add-input"
                          placeholder="Code"
                          value={branch_code}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Contact Name"
                        className={`${
                          point_of_Contact
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          name="point_of_Contact"
                          className="form-control form add-input"
                          placeholder="Contact Name"
                          value={point_of_Contact}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Contact Number"
                        className={`${
                          mobile
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          name="mobile"
                          className="form-control form add-input"
                          placeholder="Contact Number"
                          maxLength={10}
                          value={mobile}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Contact Email"
                        className={`${
                          email
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="email"
                          autoComplete="off"
                          name="email"
                          className="form-control form add-input"
                          placeholder="Contact Email"
                          value={email}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select Country"
                        className={`${
                          country
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          name="country"
                          options={countryList}
                          placeholder="Select Country"
                          value={countryName.value ? countryName : ""}
                          onInputChange={countryList.label}
                          onChange={(data) =>
                            handleSelectChange(data, "country")
                          }
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select Zone"
                        className={`${
                          zone
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={zoneList}
                          placeholder="Select Zone"
                          value={zoneName.value ? zoneName : ""}
                          onInputChange={zoneList.label}
                          onChange={(data) => handleSelectChange(data, "zone")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select State"
                        className={`${
                          state
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={stateList}
                          placeholder="Select State"
                          value={stateName.value ? stateName : ""}
                          onInputChange={stateList.label}
                          onChange={(data) => handleSelectChange(data, "state")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-3">
                      <FloatingLabel
                        label="Select City"
                        className={`${
                          city
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          options={cityList}
                          placeholder="Select City"
                          value={cityName.value ? cityName : ""}
                          onInputChange={cityList.label}
                          onChange={(data) => handleSelectChange(data, "city")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-3">
                      <FloatingLabel
                        label="Location *"
                        className={`${
                          location
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <textarea
                          rows="3"
                          cols="20"
                          wrap="hard"
                          name="location"
                          className="form-control form-textarea add-user-input"
                          id="exampleFormControlInput1"
                          placeholder="Location (Please click the icon for location)"
                          value={location}
                          onChange={(e) => handleInputData(e)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
                <div className="row dflex-j-between">
                  {props.branchID && (
                    <div className="dflex-j-start">
                      <div className="mb-2">Enable</div>&nbsp;&nbsp;&nbsp;
                      {activeInactiveOptions.map((item, i) => (
                        <div key={i}>
                          <Form.Check
                            inline
                            label={item?.label}
                            value={item?.value}
                            name="status"
                            type="radio"
                            id={`inlineradio-enableStatus-${i}`}
                            onChange={(e) => handleInputData(e)}
                            checked={item?.value == status ? true : false}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="add-page-save-cancel">
                    <SBSaveUpdateBtn btnName="Save" />
                    <SbCancelBtn btnName="Cancel" onClickEffect={closeModel} />
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
}
