import React, { useState, useEffect, useMemo, useLayoutEffect } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import "../../../styles/VhdBranch.scss";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { activeInactiveOptions } from "../../DropdownsData";
import { MultiSelect } from "react-multi-select-component";
import {
  loadStateList,
  loadCityList,
  loadUserDropdown,
} from "../../../redux/action";
import { baseURL } from "../../BaseUrl";
import { travelizeAuth } from "../../HeaderAuthentication";
import { loginSubscriptionID } from "../../LoginRole";

export default function AddEditVendorModule(props) {
  let dispatch = useDispatch();
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);
  const { userDropdown } = useSelector((state) => state.data);

  const [mappedUsers, setMappedUsers] = useState([]);
  const [stateID, setStateID] = useState({ value: null, label: "" });
  const [cityID, setCityID] = useState({ value: null, label: "" });
  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [vendorData, setVendorData] = useState({
    vendor_id: "",
    vendor_Name: "",
    contact_Person: "",
    vendor_Email: "",
    mobile_Number: "",
    branch: "",
    city: "",
    state: "",
    location: "",
    mapped_To: "",
    vendor_Status: "active",
    subscriptionId: loginSubscriptionID,
    password: "",
  });

  const {
    vendor_id,
    vendor_Name,
    contact_Person,
    vendor_Email,
    mobile_Number,
    branch,
    city,
    state,
    location,
    mapped_To,
    vendor_Status,
    password,
  } = vendorData;

  useEffect(() => {
    dispatch(loadStateList(0));
    dispatch(loadCityList(0));
    dispatch(loadUserDropdown());
  }, []);

  const handleInputData = (e) => {
    const { name, value } = e.target;
    setVendorData({ ...vendorData, [name]: value });
  };

  const handleSelectChange = (data, type) => {
    setVendorData({ ...vendorData, [type]: data.label });
    switch (type) {
      case "state":
        setStateID(data);
        break;
      case "city":
        setCityID(data);
        break;
      default:
    }
  };

  const handleMultiSelect = (selectedList) => {
    setMappedUsers(selectedList);
    let mappedUsers = selectedList.map((d) => d.label).toString();
    setVendorData({ ...vendorData, ["mapped_To"]: mappedUsers });
  };

  console.log("ADD-VENDOR-DATA:", vendorData);

  const addVendorDetails = (e) => {
    e.preventDefault();
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(vendorData),
    };
    fetch(`${baseURL}/api/VHD/AddVHD`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          props.fetchVendorDetails();
          closeModel();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const closeModel = () => {
    props.handleClose();
    props.setVendorID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={props.vendorID ? "Edit Vendor" : "Add Vendor"}
        formSubTitle={
          props.vendorID
            ? "Edit and update the vendor details."
            : "Please fill the vendor details."
        }
        sidebarItems={["Vendor"]}
        childrens={
          <>
            <form>
              <div className="add-branch-to-vhd">
                <div className="add-branch-details">
                  <div className="row">
                    <div className="col my-2">
                      <FloatingLabel
                        label="Vendor Code"
                        className={`${
                          vendor_id
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          className="form-control form add-input"
                          name="vendor_id"
                          placeholder="Vendor Code"
                          value={vendor_id}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Vendor Name"
                        className={`${
                          vendor_Name
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          name="vendor_Name"
                          className="form-control form add-input"
                          placeholder="Vendor Name"
                          value={vendor_Name}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>

                    <div className="col my-2">
                      <FloatingLabel
                        label="Contact Person"
                        className={`${
                          contact_Person
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          autoComplete="off"
                          name="contact_Person"
                          className="form-control form add-input"
                          placeholder="Contact Person"
                          value={contact_Person}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Contact Email"
                        className={`${
                          vendor_Email
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="email"
                          autoComplete="off"
                          name="vendor_Email"
                          className="form-control form add-input"
                          placeholder="Contact Email"
                          value={vendor_Email}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col my-2">
                      <FloatingLabel
                        label="Contact Number"
                        className={`${
                          mobile_Number
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="number"
                          autoComplete="off"
                          className="form-control form add-input"
                          name="mobile_Number"
                          placeholder="Contact Number"
                          maxLength={12}
                          value={mobile_Number}
                          onChange={(e) => handleInputData(e)}
                          required
                        />
                      </FloatingLabel>
                    </div>

                    <div className="col my-2">
                      <FloatingLabel
                        label="Select State"
                        className={`${
                          state
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          name="state"
                          options={stateList}
                          placeholder="Select State"
                          value={stateID.value ? stateID : ""}
                          onInputChange={stateID.label}
                          onChange={(data) => handleSelectChange(data, "state")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Select City"
                        className={`${
                          city
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          name="city"
                          options={cityList}
                          placeholder="Select City"
                          value={cityID.value ? cityID : ""}
                          onInputChange={cityID.label}
                          onChange={(data) => handleSelectChange(data, "city")}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="col-md-3 my-2">
                      <FloatingLabel
                        label="Map Users"
                        className={`${
                          mappedUsers.length !== 0
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <MultiSelect
                          options={userDropdown}
                          value={mappedUsers}
                          onChange={handleMultiSelect}
                          labelledBy={"Select"}
                          overrideStrings={{
                            selectSomeItems: "Select Users",
                          }}
                        />
                      </FloatingLabel>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col my-2">
                      <div>
                        <FloatingLabel
                          label="Branch"
                          className={`${
                            branch
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            name="branch"
                            placeholder="Branch"
                            value={branch}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="mt-3">
                        <FloatingLabel
                          label="Password"
                          className={`${
                            password
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="text"
                            autoComplete="off"
                            className="form-control form add-input"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => handleInputData(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </div>
                    <div className="col my-2">
                      <FloatingLabel
                        label="Location / Address :"
                        className={`${
                          location
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <textarea
                          rows="3"
                          cols="20"
                          name="location"
                          wrap="hard"
                          className="form-control form-textarea add-user-input"
                          placeholder="Location / Address"
                          value={location}
                          onChange={(e) => handleInputData(e)}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                </div>
                <div className="row dflex-j-between">
                  {props.vendorID && (
                    <div className="dflex-j-start">
                      <div className="mb-2">Enable</div>&nbsp;&nbsp;&nbsp;
                      {activeInactiveOptions.map((item, i) => (
                        <div key={i}>
                          <Form.Check
                            inline
                            label={item?.label}
                            value={item?.value}
                            name="vendor_Status"
                            type="radio"
                            id={`inlineradio-vendorStatus-${i}`}
                            onChange={handleInputData}
                            checked={
                              item?.value == vendor_Status ? true : false
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="add-page-save-cancel">
                    <SBSaveUpdateBtn
                      btnName="Save"
                      onClickEffect={addVendorDetails}
                    />
                    <SbCancelBtn btnName="Cancel" onClickEffect={closeModel} />
                  </div>
                </div>
              </div>
            </form>
          </>
        }
      />
    </div>
  );
}
