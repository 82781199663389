import { baseURL } from "../Components/BaseUrl";
import * as types from "./actionType";
import * as HeaderAuthentication from "../Components/HeaderAuthentication";
import * as swal from "../Components/Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// TopBar page Breadcome Value
const getThemeColor = (theme) => ({
  type: "Theme_Color",
  payload: theme,
});
const getBeadComeData = (pageData) => ({
  type: types.Get_BreadCome_Data,
  payload: pageData,
});
const getAdminAccess = (access) => ({
  type: types.Get_Admin_Access,
  payload: access,
});
const getSupportAccess = (supportAccess) => ({
  type: types.Get_Support_Access,
  payload: supportAccess,
});

export const getToataldataCount = (totalCount) => ({
  type: types.Get_Total_Data,
  payload: totalCount,
});
// TopBar page Breadcome Value
const getSubscription = (subcriptionList) => ({
  type: types.Get_Subscription,
  payload: subcriptionList,
});
const getSubscrDropdown = (subCrDropdown) => ({
  type: types.Get_Subscr_Dropdown,
  payload: subCrDropdown,
});
const getRolesDropDown = (rolesDropdown) => ({
  type: types.Get_Roles_DropDown,
  payload: rolesDropdown,
});
export const getSuccessMessage = (successMsg) => ({
  type: types.Get_Successs_Msg,
  payload: successMsg,
});

const getAllSubscriptions = (allSubcriptions) => ({
  type: types.Get_All_Subscription,
  payload: allSubcriptions,
});
const getMainModules = (mainModules) => ({
  type: types.Get_Main_Modules,
  payload: mainModules,
});
const getSubscriptionBySubscriber = (sbcrBySubscriber) => ({
  type: types.Get_Subscription_Module,
  payload: sbcrBySubscriber,
});
const getAllSubScriPtion = (allSubsById) => ({
  type: types.Get_All_Id_Subs,
  payload: allSubsById,
});
const getAllMenus = (allMenus) => ({
  type: types.Get_All_Menus,
  payload: allMenus,
});

// const postMenuItems = () => ({
//   type: types.Post_Menus_Subscription,
// });
const getTrackData = (trackData) => ({
  type: types.Get_Track_User,
  payload: trackData,
});
const getDistanceMatrix = (distanceMatrix) => ({
  type: types.Get_Distance_Matrix,
  payload: distanceMatrix,
});

// Dashboard page Graph API's
const getGraphData = (graphData) => ({
  type: types.Get_GraphData,
  payload: graphData,
});
const getGraphCount = (graphCount) => ({
  type: types.Get_GraphCount,
  payload: graphCount,
});
// Dashboard page Graph API's

// Category page
const getClientCaregory = (clientCategoryData) => ({
  type: types.Get_Client_Category,
  payload: clientCategoryData,
});
const getSingleClientCaregory = (singleClientCategoryData) => ({
  type: types.Get_Single_Client_Category,
  payload: singleClientCategoryData,
});
const getClientReportingCaregory = (clientReportingCategoryData) => ({
  type: types.Get_Client_Reporting_Category,
  payload: clientReportingCategoryData,
});
const getClientCaregoryDropDown = (categoryDropdown) => ({
  type: types.Client_Category_DropDown,
  payload: categoryDropdown,
});
const updateClientCategoryData = () => ({
  type: types.Update_Client_Category,
});
const postClientCategoryData = () => ({
  type: types.Post_Client_Category,
});
const updateCategoryData = () => ({
  type: types.Edit_Client_Category,
});
// Category page

// Stage page
const getClientStage = (clientStageData) => ({
  type: types.Get_Client_Stage,
  payload: clientStageData,
});
const clientStageDropdown = (stageDropdown) => ({
  type: types.Stage_DropDown,
  payload: stageDropdown,
});

const postClientStageData = () => ({
  type: types.Post_Client_Stage,
});
const updateClientStageData = () => ({
  type: types.Update_Client_Stage,
});
// Stage page

// Custom Field page
const postCustomField = () => ({
  type: types.Post_Custom_Fields,
});
const getCustomFields = (customFields) => ({
  type: types.Get_Custom_Fields,
  payload: customFields,
});
const getSingleField = (singleField) => ({
  type: types.Get_Single_Filed,
  payload: singleField,
});
const updateCustomField = () => ({
  type: types.Update_Custom_Fields,
});
// Custom Field page

// Company page
const getCompanyData = (companyData) => ({
  type: types.Get_Company_Data,
  payload: companyData,
});
const getReportingCompanyData = (companyReportingData) => ({
  type: types.Get_Reporting_Client_Data,
  payload: companyReportingData,
});
const getSingleClient = (singleClient) => ({
  type: types.Get_Single_Client,
  payload: singleClient,
});

const postOtherAddress = () => ({
  type: types.Post_Other_Address,
});

const postOtherContact = () => ({
  type: types.Post_Other_Contact,
});

const getOtherAddress = (otherAddress) => ({
  type: types.Get_Other_Address,
  payload: otherAddress,
});
const getOtherContact = (otherContacts) => ({
  type: types.Get_Other_Contact,
  payload: otherContacts,
});
const getContactByCompanyDrop = (contactByCompanyDrop) => ({
  type: types.ContactBy_Company_Drop,
  payload: contactByCompanyDrop,
});

const postCompanyData = () => ({
  type: types.Post_Comapy_Data,
});
const updateCompanyData = () => ({
  type: types.Update_Company_Data,
});

const getClientDropDown = (clientDropdown) => ({
  type: types.Client_Dropdown,
  payload: clientDropdown,
});
// Company page

// Holiday List Page
const getHolidayList = (holidayList) => ({
  type: types.Get_Holiday_List,
  payload: holidayList,
});

const postHolidayList = () => ({
  type: types.Post_Holiday_List,
});

const getSingleHoliday = (singleHoliday) => ({
  type: types.Single_Holiday_Data,
  payload: singleHoliday,
});

const getHolidayTypes = (holidayTypes) => ({
  type: types.Hliday_Types,
  payload: holidayTypes,
});

const updateHolidayList = () => ({
  type: types.Update_Holiday_Data,
});
// Holiday List Page

// Shift Page
const getShiftData = (shiftData) => ({
  type: types.Get_Shift_List,
  payload: shiftData,
});

const postShiftData = () => ({
  type: types.Post_Shift_List,
});
const getShiftDropdownData = (shiftDropdownData) => ({
  type: types.Get_Shift_Dropdown,
  payload: shiftDropdownData,
});
// Shift Page

// Client Compalin Page
const getClientComplaint = (clientComplaints) => ({
  type: types.Get_Client_Complains,
  payload: clientComplaints,
});
const postClientComplaint = () => ({
  type: types.Post_Client_Complaint,
});
const getSingleClientCompalint = (singleClientCompaint) => ({
  type: types.Simgle_Client_Complaint,
  payload: singleClientCompaint,
});
const updateClientCompalint = () => ({
  type: types.Update_Client_Complaint,
});
// Client Compalin Page

// Products Page
const getProductsList = (productData) => ({
  type: types.Get_Products_List,
  payload: productData,
});
const getSingleProduct = (singleProduct) => ({
  type: types.Get_Single_Product,
  payload: singleProduct,
});
const getProductsDropdown = (productDropdown) => ({
  type: types.Get_Product_DropDown,
  payload: productDropdown,
});
const postProductData = () => ({
  type: types.Post_Product_Data,
});
const updateProductData = () => ({
  type: types.Update_Product_Data,
});

export const getCartListData = (cartList) => ({
  type: types.Get_Cart_List,
  payload: cartList,
});
// Products Page

// Orders Page
const getOrders = (oderDetails) => ({
  type: types.Get_Orders,
  payload: oderDetails,
});
const getSingleOrder = (singleOrder) => ({
  type: types.Get_Single_Orders,
  payload: singleOrder,
});
const getProducsByOrder = (productsByOrder) => ({
  type: types.Get_ProductsBy_Order,
  payload: productsByOrder,
});
const getOrderHistory = (orderHistory) => ({
  type: types.Get_Order_History,
  payload: orderHistory,
});
const updateOrderQty = () => ({
  type: types.Update_Product_Qty,
});
// Orders Page

// Meeting Page
const getMeetingData = (meetingData) => ({
  type: types.Get_Meetings_Data,
  payload: meetingData,
});
const getMeetingDetails = (meetingDetailsData) => ({
  type: types.Get_MeetingsDetails_Data,
  payload: meetingDetailsData,
});
const getMeetingCounts = (meetingCountsData) => ({
  type: types.Get_MeetingsCount_Data,
  payload: meetingCountsData,
});
const getMeetingPurposeData = (meetingPurposeData) => ({
  type: types.Get_MeetingPurpose_Data,
  payload: meetingPurposeData,
});
// Meeting Page

// Product Category Page
const getProdCategoryDrop = (prodCategoryDrop) => ({
  type: types.Get_ProdCategory_Dropdown,
  payload: prodCategoryDrop,
});
const getProdSubCategory = (prodSubcategory) => ({
  type: types.Get_Prod_Sub_Category,
  payload: prodSubcategory,
});
// Product Category Page

// Designation Page
const getDesignationDrop = (desigationdrop) => ({
  type: types.Get_Designation_Dropdown,
  payload: desigationdrop,
});
const getdepartDesignation = (departDesignation) => ({
  type: types.Get_Designation_Department,
  payload: departDesignation,
});
// Designation Page

// Basefare page
// mot dropdown
const getMotDrop = (motDrop) => ({
  type: types.Get_Mot_Dropdown,
  payload: motDrop,
});
// mot dropdown
const getBaseFare = (baseFareList) => ({
  type: types.Get_BaseFare_List,
  payload: baseFareList,
});

const addBadseFareTolist = () => ({
  type: types.Post_Basefare,
});

export const getSingleBasefare = (singleBaseFire) => ({
  type: types.Get_Single_BaseFare,
  payload: singleBaseFire,
});

const updateBasefare = () => ({
  type: types.Update_Basefare,
});
// Basefare page

// Department Page
const getDepartmentDrop = (departmentDrop) => ({
  type: types.Get_Department_Dropdown,
  payload: departmentDrop,
});
// Department Page

// Get Apis for CountryCodes, Zone, Country, State, City

const getCountryCodes = (countryCodes) => ({
  type: types.Get_CountryCode_List,
  payload: countryCodes,
});

const getZoneList = (zoneList) => ({
  type: types.Get_Zones,
  payload: zoneList,
});

const getStateList = (stateList) => ({
  type: types.Get_States_ByZoneId,
  payload: stateList,
});

const getCityList = (cityList) => ({
  type: types.Get_City_ByStateID,
  payload: cityList,
});
// Get Apis for CountryCodes, Zone, Country, State, City

// Upload Imagez
// const uploadSingeImage = (imgUrl) => ({
//   type: types.Upload_Single_Image,
//   payload: imgUrl,
// });
// Upload Imagez

// SuperAdmin Page
// Google credit page
const getGoogleCredits = (googleCreditList) => ({
  type: types.Get_Google_Credits,
  payload: googleCreditList,
});
const getApiNameDrop = (apiDropdown) => ({
  type: types.Get_API_Dropdown,
  payload: apiDropdown,
});
const getCreditCount = (creditCount) => ({
  type: types.Get_Credits_Count,
  payload: creditCount,
});
const getAPICredits = (ApiCreditList) => ({
  type: types.Get_API_Credits,
  payload: ApiCreditList,
});
const getGoogleApiHitCount = (ApiHitList) => ({
  type: types.GET_APT_HITS,
  payload: ApiHitList,
});
const addGoogleCredits = () => ({
  type: types.Post_Google_Credits,
});
const postAPICredits = () => ({
  type: types.Post_Api_Credits,
});
const getGoogleApiNameDropdown = (apiNameDropdown) => ({
  type: types.Get_GoogleApiName_Drop,
  payload: apiNameDropdown,
});
const getSingleApiData = (apiSingleData) => ({
  type: types.Get_Single_ApiCredit,
  payload: apiSingleData,
});
const getExceptionData = (apiExceptionData) => ({
  type: types.Get_Exception,
  payload: apiExceptionData,
});
const getMobileExceptionData = (apiMobileExceptionData) => ({
  type: types.Get_Mobile_Exception,
  payload: apiMobileExceptionData,
});

const getOtp = (otpData) => ({
  type: types.Get_Otp_Data,
  payload: otpData,
});
// Google credit page
// Leads Data
const getLeadsCardDetails = (getLeadsCardData) => ({
  type: types.get_leads_card_data,
  payload: getLeadsCardData,
});
const getLeadsTableDetails = (getLeadsTableData) => ({
  type: types.get_leads_table_data,
  payload: getLeadsTableData,
});
const getLeadGenSubScriptionReportTableDetails = (
  getLeadGenSubReportTableData
) => ({
  type: types.get_lead_gen_Subscription_Report_table_data,
  payload: getLeadGenSubReportTableData,
});
// Leads Data
// SuperAdmin Page

// User Page
const getUserDropdown = (userDropdown) => ({
  type: types.Get_User_Dropdown,
  payload: userDropdown,
});

const getUserDropdownByManager = (userDropdownByManager) => ({
  type: types.Get_User_Dropdown_ByManager,
  payload: userDropdownByManager,
});

const getUserDropdownByCompany = (userDropdownByCompany) => ({
  type: types.Get_User_Dropdown_By_Company,
  payload: userDropdownByCompany,
});

const getUserRegionResult = (userRegionResult) => ({
  type: types.Get_User_Regions,
  payload: userRegionResult,
});

const getAllUserDetails = (allUserDetails) => ({
  type: types.Get_Allser_Details,
  payload: allUserDetails,
});
// User Page

// Leave Page
const getLeaveTypeDropdown = (leaveTypeDropdown) => ({
  type: types.Get_LeaveType_Dropdown,
  payload: leaveTypeDropdown,
});
// Leave Page

const getManagerDropdown = (managerDropdown) => ({
  type: types.Get_Manager_Dropdown,
  payload: managerDropdown,
});
// Google Hit Count
// const getHitCount = (hitCount) => ({
//   type: types.Get_Hit_Count,
//   payload: hitCount
// })
// Google Hit Count

// Subscription Pages
const updateSubscription = () => ({
  type: types.Update_Subscription,
});
const getSubscriptionDrop = (subscriptionTypeDropdown) => ({
  type: types.get_Subscription_Type_Dropdown,
  payload: subscriptionTypeDropdown,
});
// Subscription Pages
// Refferal Pages
const getSalesBeeRefferal = (salesBeeRefferal) => ({
  type: types.get_SalesBee_Refferal,
  payload: salesBeeRefferal,
});
const postSalesBeeRefferal = () => ({
  type: types.post_SalesBee_Refferal,
});
const upDateSalesBeeRefferal = () => ({
  type: types.update_SalesBee_Refferal,
});
const getSingleRefferalData = (apiSingleRefferalData) => ({
  type: types.Get_Single_Refferal_Data,
  payload: apiSingleRefferalData,
});
// Refferal Pages

// MapClientCategory
const postClientCategoryMapping = () => ({
  type: types.post_clientCategory_Map,
});
const getMapClientCategory = (clientCategoryMap) => ({
  type: types.get_clientCategory_Map,
  payload: clientCategoryMap,
});
// MapClientCategory

// Hierarchy Page
const getHierarchyData = (hierarchyData) => ({
  type: types.get_Hierarchy_Data,
  payload: hierarchyData,
});
// Hierarchy Page

// ProductFilter Page
const postProductFilter = () => ({
  type: types.post_productFilter_Data,
});
const getProductFilterData = (productFilterData) => ({
  type: types.get_productFilter_Data,
  payload: productFilterData,
});
const getSingleProductFilterData = (singleProductFilter) => ({
  type: types.get_Single_productFilter_Data,
  payload: singleProductFilter,
});
const updateProductFilterData = () => ({
  type: types.update_productFilter_Data,
});
// ProductFilter Page

// UserReferrals Page
const getUserReferralsData = (userReferralsData) => ({
  type: types.get_UserReferrals_Data,
  payload: userReferralsData,
});
const getUserReferralsDataDropDOwn = (userReferralsDataDropDown) => ({
  type: types.get_UserReferrals_DataDropDown,
  payload: userReferralsDataDropDown,
});
const postUserBankDetailsData = () => ({
  type: types.post_UserReferrals_BankDetails,
});
const getSingleUserReferralsData = (singleUserReferralsData) => ({
  type: types.get_SingleUserReferrals_Data,
  payload: singleUserReferralsData,
});
const getUserReferralsDropDown = (userReferralsDropdown) => ({
  type: types.get_UserReferrals_DropDown,
  payload: userReferralsDropdown,
});
// UserReferrals Page
// Referral Product page
const getProductReferralData = (productReferralData) => ({
  type: types.get_referralProduct_Data,
  payload: productReferralData,
});
// Referral Product page

//  Commission Page
const getCommissionData = (commissionData) => ({
  type: types.get_commission_Data,
  payload: commissionData,
});
const postCommissionData = () => ({
  type: types.post_commission_Data,
});
const getProductDropdownData = (productsData) => ({
  type: types.get_ProductDropdown_Data,
  payload: productsData,
});
const getSingleCommissionData = (singleCommissionData) => ({
  type: types.get_SingleCommission_Data,
  payload: singleCommissionData,
});
//  Commission Page

// Passbook Page
const getPassbookCardDetails = (cardData) => ({
  type: types.get_PassbookCard_Data,
  payload: cardData,
});
const getTransactionSummary = (transactionData) => ({
  type: types.get_Transaction_Data,
  payload: transactionData,
});
const getUserBankData = (userBankData) => ({
  type: types.get_userBank_Data,
  payload: userBankData,
});
const getUserPassbookDetails = (userPassbookDetails) => ({
  type: types.get_userPassbookDetails,
  payload: userPassbookDetails,
});
const getPassbookSummeryData = (userPassbookSummeryData) => ({
  type: types.get_PassbookSummery_Data,
  payload: userPassbookSummeryData,
});
const getPassbookSummeryDataByProfile = (userPassbookSummeryById) => ({
  type: types.get_PassbookSummery_ById,
  payload: userPassbookSummeryById,
});
const getUserTransactionDetails = (userTransactionDetails) => ({
  type: types.get_userTransaction_Details,
  payload: userTransactionDetails,
});
// Passbook Page

// Payment Page
const getPaymentData = (PaymentData) => ({
  type: types.get_Payment_Data,
  payload: PaymentData,
});
const getSinglePaymentData = (singlePaymentData) => ({
  type: types.get_Single_Payment_Data,
  payload: singlePaymentData,
});
const postPaymentData = () => ({
  type: types.add_Payment_Data,
});
// Payment Page

// SignupSubscription Page
const getSignupSubscriptionData = (signUpSubscriptionData) => ({
  type: types.get_SignupSubscription_Data,
  payload: signUpSubscriptionData,
});
// SignupSubscription Page

// CreateSubscription Page
const getReferredByDropdownData = (getReferredByDropdownData) => ({
  type: types.get_ReferredBy_Dropdown,
  payload: getReferredByDropdownData,
});
// CreateSubscription Page

// Payroll page
const getPayrollData = (getPayrollData) => ({
  type: types.get_Payroll_Data,
  payload: getPayrollData,
});
const postEmployeePayroll = () => ({
  type: types.post_Employee_Payroll,
});
const postEmployeeFixedSalary = () => ({
  type: types.post_FixedSalary_Data,
});
const deleteEmployeePayroll = () => ({
  type: types.delete_Employee_Payroll,
});
// Payroll page

// Region Page
const getCountryName = (getCountryName) => ({
  type: types.get_Country_Name,
  payload: getCountryName,
});
const getDropdownStateName = (getDropdownState) => ({
  type: types.get_Dropdown_State_Name,
  payload: getDropdownState,
});
const getAllStateList = (getAllStateName) => ({
  type: types.get_AllState_Name,
  payload: getAllStateName,
});
const getAllCityList = (getAllCityName) => ({
  type: types.get_AllCity_Name,
  payload: getAllCityName,
});
const postStateName = () => ({
  type: types.Add_State_Name,
});
const postCityName = () => ({
  type: types.Add_City_Name,
});
// Region Page

// OrthoAttendence page
const getOrthoAttendence = (getOrthoAttendence) => ({
  type: types.get_OrthoAttendence,
  payload: getOrthoAttendence,
});
// OrthoAttendence page

// TravelReport Page
const getTravelAttachmentDetails = (getTravelAttachments) => ({
  type: types.get_TravelReport_Attachment,
  payload: getTravelAttachments,
});
const getSingleTravelReportData = (getSingleTravelReportData) => ({
  type: types.get_Single_TravelReport_Data,
  payload: getSingleTravelReportData,
});
// TravelReport Page

// Route Deviation Page
const getRouteDeviationData = (getRouteDeviation) => ({
  type: types.get_Route_Deviation_Data,
  payload: getRouteDeviation,
});
// Route Deviation Page

// Claim Hirarchy Page
const getClaimHirarchyData = (getClaimHirarchy) => ({
  type: types.get_Claim_Hirarchy_Data,
  payload: getClaimHirarchy,
});
// Claim Hirarchy Page

// User Activity Page
const getUserActivityData = (getUserActivity) => ({
  type: types.get_User_Activity_Data,
  payload: getUserActivity,
});

// Kpa Page
const getKpaData = (getKpa) => ({
  type: types.get_Kpa_Data,
  payload: getKpa,
});
const addKpaData = () => ({
  type: types.post_Kpa_Data,
});
const getSingleKpa = (getSingleKpaData) => ({
  type: types.get_Single_Kpa_Data,
  payload: getSingleKpaData,
});
// Kpa Page

// Account Settings Page
const postDirectVisitData = () => ({
  type: types.post_Direct_Visit_Data,
});
const postCheckInData = () => ({
  type: types.post_BaseLocation_CheckIn_Radius,
});
const postCheckOutData = () => ({
  type: types.post_BaseLocation_CheckOut_Radius,
});
// Account Settings Page

// Machines page
const getMachines = (getMachinesData) => ({
  type: types.get_Machines_Data,
  payload: getMachinesData,
});
const addMachines = () => ({
  type: types.post_Machines_Data,
});
const getSingleMachine = (getSingleMachineData) => ({
  type: types.get_Single_Machine_Data,
  payload: getSingleMachineData,
});
// Machines page
// AnnualContract Page
const getAmc = (getAmcData) => ({
  type: types.get_AMC_Data,
  payload: getAmcData,
});
const postAmc = () => ({
  type: types.post_AMC_Data,
});
const getSingleAmc = (getSingleAmcData) => ({
  type: types.get_Single_AMC_Data,
  payload: getSingleAmcData,
});
// AnnualContract Page
// Escalation Page
const getEscalation = (getEscalationData) => ({
  type: types.get_Escalation_Data,
  payload: getEscalationData,
});
const postEscalation = () => ({
  type: types.post_Escalation_Data,
});
// Escalation Page

const getCustomeFieldsForCompany = (getCustomFieldsForCompany) => ({
  type: types.get_Custome_Fields_For_Company,
  payload: getCustomFieldsForCompany,
});
const getSingleCustomeFieldsForCompany = (getSingleCustomFieldsForCompany) => ({
  type: types.get_Single_Custome_Fields_For_Company,
  payload: getSingleCustomFieldsForCompany,
});
const postCustomeFieldsForCompany = () => ({
  type: types.post_Custom_Fields_For_Company,
});

// VHD MODULE
const fetchAllVendorDetails = (getAllVendorDetails) => ({
  type: types.Get_All_Vendor_Details,
  payload: getAllVendorDetails,
});

const fetchAllBranchDetails = (getAllBrnachDetails) => ({
  type: types.Get_All_Branch_Details,
  payload: getAllBrnachDetails,
});

const fetchAllPODetails = (getAllPODetails) => ({
  type: types.Get_All_PO_Details,
  payload: getAllPODetails,
});

const fetchAllInvDetails = (getALlInvoiceDetails) => ({
  type: types.Get_All_Invoice_Details,
  payload: getALlInvoiceDetails,
});

// VHD MODULE

// topbar page breadcome
export const loadThemeColor = (theme) => {
  return function (dispatch) {
    dispatch(getThemeColor(theme));
  };
};
export const loadPageBreadCome = (braedCome) => {
  return function (dispatch) {
    dispatch(getBeadComeData(braedCome));
  };
};
export const loadAdminAccess = (access) => {
  return function (dispatch) {
    dispatch(getAdminAccess(access));
  };
};
export const loadSupportAccess = (supportAccess) => {
  return function (dispatch) {
    dispatch(getSupportAccess(supportAccess));
  };
};
// topbar page breadcome

// Service call for page
export const loadSubscription = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscription?PageSize=1000`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSubscription(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadSubsrDropdown = (subType) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/BindCompanyDropdown${
        subType ? `?SubscriptionTypeID=${subType}` : ``
      }`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getSubscrDropdown(
              resp.result.map((data) => {
                return {
                  value: data.subscriptionID,
                  label: data.companyName,
                };
              })
            )
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadRolesDropDown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Role/BindRolesDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getRolesDropDown(
            resp.result.map((data) => {
              return {
                value: data.roleID,
                label: data.roleName,
                roleId: data.roleID,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadAllSubscriptions = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetMainModule`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getAllSubscriptions(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadMainModules = (SubscriptionID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionMainModule?SubscriptionID=${SubscriptionID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getMainModules(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadSubscriptionBySubscriber = (sbctptnId, moduleId, roleId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?SubscriptionID=${sbctptnId}&MainModuleID=${moduleId}&RoleID=${roleId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSubscriptionBySubscriber(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadSubscriptionBySubsId = (sbctptnId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?SubscriptionID=${sbctptnId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getAllSubScriPtion(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadAllMenus = (moduleId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?MainModuleID=${moduleId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getAllMenus(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostMenuItems = (postData, closeAlertModel) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/BulkAddSubscriptionModule`,
      postData
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.Success === false) {
          toast.error(resp.errorMessage);
        } else {
          swal.showAlert(
            "Success",
            "Menu Items Added successfully...!",
            "success",
            closeAlertModel
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadTrackData = (tokenData) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Annonymus/GetCordsUserID?Token=${tokenData}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getTrackData(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadGetDistance = (subscriptionId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/GetAllPermissionStatus/${subscriptionId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getDistanceMatrix(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostDistance = (postRequest) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Subscription/UpdateDistanceMatrix`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          swal.showAlert(
            "Success",
            "Distance Matrix Updated successfully...!",
            "success"
          );
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};
export const loadNotification = (postRequest, lastIndex) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Subscription/UpdatePermissionStatus`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errors) {
          toast.error(resp.errorMessage);
        } else {
          if (lastIndex) {
            swal.showAlert(
              "Success",
              "Permission Status Updated successfully...!",
              "success"
            );
          }
        }
      })
      .catch((err) => {});
  };
};

// DashBoard Page Graph API's
export const loadGraphData = (graphName, sort, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/DashBoard/GetContributionsGraph?GraphName=${graphName}&Sort=${sort}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getGraphData(resp.result));
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadGraphCount = (sort) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/DashBoard/GetContributionsGraph?GraphName=ModuleDataCount&Sort=${sort}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getGraphCount(resp.result));
      })
      .catch((err) => {});
  };
};
// DashBoard Page Graph API's

// Category Page
// api/ClientCategory/Get
export const loadClientCategory = (
  PageSize,
  CurrentPage,
  status,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategory/Get?PageSize=${PageSize}&CurrentPage=${CurrentPage}&StatusID=${status}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getClientCaregory(resp.result));
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
};
export const loadSingleClientCategory = (categoryId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategory/Get/${categoryId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleClientCaregory(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadClientReportingCategory = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategory/GetReportingCategory`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp, "resp");
        dispatch(getClientReportingCaregory(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadClientCategoryDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategory/BindDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getClientCaregoryDropDown(
            resp.result.map((data) => {
              return { value: data.categoryID, label: data.name };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

// /api/ClientCategory/AddCategory
export const postClientCategory = (
  postRequest,
  cancelModale,
  categoryId,
  fetchCategoryData
) => {
  return function (dispatch) {
    if (categoryId) {
      fetch(`${baseURL}/api/ClientCategory/UpdateCategory`, postRequest)
        .then((resp) => {
          return resp.json();
        })
        .then((resp) => {
          if (resp.success) {
            dispatch(updateCategoryData());
            fetchCategoryData();
            cancelModale();
            swal.showAlert(
              "Success",
              "API Credits Updated successfully...!",
              "success"
            );
          } else {
            toast.error(resp.errorMessage);
          }
        });
    } else {
      fetch(`${baseURL}/api/ClientCategory/AddCategory`, postRequest)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postClientCategoryData());
            dispatch(loadClientCategory());
            cancelModale();
            swal.showAlert(
              "Success",
              "Category Added Successfully...!",
              "success"
            );
          } else {
            toast.error(resp.errorMessage);
          }
        })
        .catch((err) => {
          toast.error(`${err}`);
        });
    }
  };
};

// /api/ClientCategory/UpdateCategory
export const updateClientCategory = (updateData, setBtnStatus) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientCategory/UpdateCategory`, updateData)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateClientCategoryData(resp));
          dispatch(loadClientCategory(100, 1, 2));
          swal.showAlert(
            "Success",
            "Status Update Successfully...!",
            "success"
          );
          setBtnStatus(false);
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};
// Category page

// Stage page
export const loadClientStage = (
  PageSize,
  currentPage,
  setTotalCounts,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientStage/Get?PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getClientStage(resp.result));
          dispatch(getToataldataCount(resp.recordCount[0]));
          setTotalCounts(resp.recordCount);
          setIsLoading(false);
          setLoading(false);
        } else {
          dispatch(getClientStage(resp.result));
        }
      })
      .catch((err) => {});
  };
};

export const loadClientStageGrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientStage/BindDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          clientStageDropdown(
            resp.result.map((data) => {
              return { value: data.stageID, label: data.name };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

// /api/ClientStage/AddStage
export const postClientStage = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientStage/AddStage`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postClientStageData());
          dispatch(loadClientStage(20, 1));
          cancelModale();
          swal.showAlert("Success", "Stage Added Successfully...!", "success");
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};

//  /api/ClientStage/UpdateStage
export const updateClientStage = (updateData, setBtnStatus) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientStage/UpdateStage`, updateData)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateClientStageData(resp));
          dispatch(loadClientStage(20, 1));
          swal.showAlert(
            "Success",
            "Status Update Successfully...!",
            "success"
          );
          setBtnStatus(false);
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};
// Stage page

// Custom Field page
export const loadCustomFields = (
  PageSize,
  currentPage,
  setTotalData,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFields/Get?CustomeFieldID=0&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCustomFields(resp.result));
        setTotalData(resp.recordCount[0]);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadPostCustomfields = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeFields/AddCustomeFields`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          toast.error(resp.errorMessage);
        } else {
          cancelModale();
          dispatch(postCustomField());
          swal.showAlert(
            "Success",
            "Custom Field Created Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};

export const loadSingleField = (fieldId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFields/Get/${fieldId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleField(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadUpdateCustomfields = (updateRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeFields/UpdateCustomeFields`, updateRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          cancelModale();
          dispatch(updateCustomField());
          swal.showAlert(
            "Success",
            "Custom Field Updated Successfully...!",
            "success"
          );
        } else {
          // swal.showAlert("Failis", "Something went wrong...!", "warning");
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};
// Custom Field page

// Meetings Details Page

export const loadGetMeetingDetails = (
  PageSize,
  currentPage,
  setTotalCounts,
  setIsLoading,
  setLoading,
  fromDate,
  toDate,
  filterUserId,
  stateId
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Meeting/GetMeetingDetails?PageSize=${PageSize}&CurrentPage=${currentPage}&UserId=${filterUserId}&FromDate=${fromDate}&ToDate=${toDate}&stateId=${stateId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getMeetingDetails(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        setTotalCounts(resp.recordCount);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};

// Meetings Details Page

// Company page service
export const loadCompanyData = (
  PageSize,
  currentPage,
  setTotalCounts,
  setIsLoading,
  setLoading,
  loggedInId,
  id
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Client/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&ManagerID=${loggedInId}&CategoryID=${id}&EnabledStatus=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getCompanyData(resp.result));
          dispatch(getToataldataCount(resp.recordCount[0]));
          setTotalCounts(resp.recordCount);
          setIsLoading(false);
          setLoading(false);
        } else {
          toast.error(resp.Message);
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
};
export const loadCompanyReportingData = (clintid) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Client/GetReportingClientDetails?clientID=${clintid}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getReportingCompanyData(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadFilterCompanyData = (
  filterArray,
  currentPage,
  PageSize,
  setTotalCounts,
  setIsLoading,
  setLoading,
  id
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }

    fetch(
      `${baseURL}/api/Client/Get?CategoryID=${id}&PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCompanyData(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        setTotalCounts(resp.recordCount);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};

export const loadGetSingleClient = (clientId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Client/Get/${clientId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleClient(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostOtherAddress = (postAddress, closeAddressForm) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientAdditionalLocation/AddClientAdditionalLocation`,
      postAddress
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postOtherAddress());
          closeAddressForm();
          swal.showAlert(
            "Success",
            "Address Added Successfully...!",
            "success"
          );
        } else {
          swal.showAlert(
            "Fail",
            "Something went wrong...! please try again",
            "warning"
          );
        }
      })
      .catch((err) => {});
  };
};

export const loadPostOtherConact = (postOtherConact, closeContactForm) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientContactPerson/AddClientContactPerson`,
      postOtherConact
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postOtherContact());
          closeContactForm();
          swal.showAlert("Success", "Conact Added Successfully...!", "success");
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};

export const loadOtherAddress = (clientId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientAdditionalLocation/Get?ClientID=${clientId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getOtherAddress(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadOtherContacts = (clientId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientContactPerson/Get?ClientID=${clientId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getOtherContact(resp.result));
        dispatch(
          getContactByCompanyDrop(
            resp.result.map((client) => {
              return { label: client.name, value: client.contactPersonID };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadPostCompanyData = (postRequest, closeModal, customFields) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Client/AddClient`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          const clientId = resp.result;
          customFields.forEach((field) => {
            field.tableID = clientId;
          });
          dispatch(postCompanyData());
          if (customFields.length !== 0) {
            let postCustomData = {
              method: "POST",
              mode: "cors",
              headers: HeaderAuthentication.postTravelizeAuth,
              body: JSON.stringify(customFields),
            };
            dispatch(addCustomeFieldsForCompany(postCustomData, closeModal));
          }
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};

export const loadUpdateCompanyData = (
  postRequest,
  closeModal,
  customFields,
  clientId,
  customeLocalImage
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Client/EditClient`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          customFields.forEach((field) => {
            field.tableID = clientId.toString();
          });
          // closeModal();
          dispatch(getSingleClient({}));
          dispatch(updateCompanyData());
          if (
            customeLocalImage &&
            customeLocalImage.file !== null &&
            customeLocalImage.file !== ""
          ) {
            const formData = new FormData();
            formData.append("Files", customeLocalImage.file);
            formData.append("Directory", "Company_Custom");
            formData.append("FileNamePrefix", customeLocalImage.fieldId);

            let requestOption = {
              method: "POST",
              mode: "cors",
              headers: HeaderAuthentication.salesbeeImgAuth,
              body: formData,
            };
            dispatch(
              loadUploadCustomImage(
                requestOption,
                customFields,
                closeModal,
                customeLocalImage
              )
            );
          } else {
            let postCustomData = {
              method: "POST",
              mode: "cors",
              headers: HeaderAuthentication.postTravelizeAuth,
              body: JSON.stringify(customFields),
            };
            dispatch(addCustomeFieldsForCompany(postCustomData, closeModal));
          }
        } else {
          closeModal();
          swal.showAlert(
            "Fails",
            `Something went wrong,  ${resp.errorMessage}`,
            "warning"
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadClientDropDown = (userID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Client/BindClientDropdown${
        userID ? `?userId=${userID}` : ""
      }`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getClientDropDown(
            resp.result.map((client) => {
              return {
                label: client.clientName,
                value: client.clientId,
                name: client.contactPersonName,
              };
            })
          )
        );
      });
  };
};
// Company page service

// Holiday List page
export const loadHolidayList = (
  state,
  PageSize,
  currentPage,
  setTotalData,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Holidays/Get?State=${state}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getHolidayList(resp.result));
        setTotalData(resp.recordCount[0]);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};

export const loadHolidayTypes = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/HolidayType/BindHolidayTypeDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getHolidayTypes(
            resp.result.map((holiday) => {
              return {
                label: holiday.holidayTypeName,
                value: holiday.holidayTypeID,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadPostHoliday = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Holidays/AddHolidays`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          toast.error(resp.errorMessage);
        } else {
          dispatch(postHolidayList());
          dispatch(loadHolidayList("", 100, 1));
          cancelModale();
          swal.showAlert(
            "Success",
            "Holiday List Added Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};

export const loadSingleHoliday = (HolidaysID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Holidays/Get/${HolidaysID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleHoliday(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadUpdateHoliday = (updateRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Holidays/UpdateHolidays`, updateRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
        } else {
          dispatch(updateHolidayList());
          dispatch(loadHolidayList("", 100, 1));
          cancelModale();
          swal.showAlert(
            "Success",
            "Holiday List Updated Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
// Holiday List page

// Shift page
export const loadShiftData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetShifts`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getShiftData(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostShift = (postRequest, cancelModale, shiftId) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Zone/AddShift`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.ErrorMessage) {
          toast.error(resp.ErrorMessage);
        } else {
          dispatch(postShiftData());
          cancelModale();
          dispatch(loadShiftData());
          swal.showAlert(
            "Success",
            shiftId
              ? "Shift Updated Successfully...!"
              : "Shift Saved Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};
export const loadShiftDropdown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/BindShiftDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getShiftDropdownData(
            resp.result.map((data) => {
              return { value: data.shiftID, label: data.shiftName };
            })
          )
        );
      })
      .catch((err) => {});
  };
};
// Shift page

// Client Complaint Page
export const loadClientComplaint = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/ClientComplaint/Get?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getToataldataCount(resp.recordCount[0]));
        dispatch(getClientComplaint(resp.result));
        setLoading(false);
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadUploadComplaintImages = (
  imgurlPost,
  complaintData,
  modleClose,
  compaintId,
  type
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, imgurlPost)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let mainImage = resp.result[0];
          if (type && type === "status") {
            complaintData.solutionImage = mainImage;
          } else {
            complaintData.issueImage = mainImage;
          }

          var postRequest = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(complaintData),
          };
          if (compaintId) {
            dispatch(updateComplaintStatus(postRequest, modleClose, "update"));
          } else {
            dispatch(loadPostClientComplaint(postRequest, modleClose));
          }
        } else {
          // alert("Something Went wrong...!");
          toast.error(resp.errorMessage);
        }
      });
  };
};

export const loadPostClientComplaint = (postRequest, modleClose) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientComplaint/AddComplaints`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          // swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
          // modleClose();
          toast.error(resp.errorMessage);
        } else {
          dispatch(postClientComplaint());
          modleClose();
          swal.showAlert(
            "Success",
            "Complaint created successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};

export const loadSingleClientCompalint = (compaintId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientComplaint/Get/${compaintId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleClientCompalint(resp.result));
      })
      .catch((err) => {});
  };
};

export const updateComplaintStatus = (postRequest, closeUpdateModal, type) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientComplaint/UpdateComplaints`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateClientCompalint());
          closeUpdateModal();
          swal.showAlert(
            "Success",
            `${
              type === "status"
                ? "Status Updated successfully...!"
                : type === "update"
                ? "Successfully updated complaint details...!"
                : "Successfully assigned to new user...!"
            }`,
            "success"
          );
        } else {
          closeUpdateModal();
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
        }
      })
      .catch((err) => {});
  };
};
// Client Complaint Page

// Products Page
export const loadProductsData = (
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Product/Get?CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getProductsList(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleProduct = (ProductID, setIsLoading) => {
  return function (dispatch) {
    if (ProductID !== null) {
      fetch(
        `${baseURL}/api/Product/Get/${ProductID}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(getSingleProduct(resp.result));
          if (setIsLoading) {
            setIsLoading(false);
          }
        })
        .catch((err) => {});
    } else {
      dispatch(getSingleProduct({}));
    }
  };
};

export const loadProductsDropDown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Product/BindDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getProductsDropdown(
            resp.result.map((product) => {
              return {
                value: product.productID,
                label: product.productName,
                code: product.productCode,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadSearchProducts = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    filterArray.map((item, i) => {
      if (i === 0 && !param) {
        return (param = item.key + "=" + item.value);
      } else {
        return (param = param + "&" + item.key + "=" + item.value);
      }
    });

    fetch(
      `${baseURL}/api/Product/Get?${param}&CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getProductsList(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadPostProduct = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Product/AddProduct`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postProductData());
          closeModal();
          swal.showAlert("Success", "Product Added Successfully", "success");
        } else {
          // swal.showAlert("Fails", "Something Went Wrong...!", "warning");
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};
export const loadUpdateProduct = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Product/UpdateProduct`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateProductData());
          closeModal();
          swal.showAlert(
            "Success",
            "Product Details Updated Successfully",
            "success"
          );
        } else {
          swal.showAlert("Fails", "Something Went Wrong...!", "warning");
        }
      })
      .catch((err) => {});
  };
};
// Products Page

// Orders Page
export const loadOrders = (
  subId,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/Get?SubscriptionID=${subId}&CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp.result, "resp.result");
        dispatch(getOrders(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadSearchOrders = (
  subId,
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    filterArray.map((item) => {
      return (param = param + "&" + item.key + "=" + item.value);
    });
    fetch(
      `${baseURL}/api/OrderManagement/Get?SubscriptionID=${subId}${param}&CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getOrders(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        // if (setIsLoading) {
        setIsLoading(false);
        // }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
};

export const loadSingleOrderData = (OrderID, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/Get/${OrderID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleOrder(resp.result));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
};
export const loadProductsDataByIdOrderId = (OrderID, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/GETBYID_Detail/${OrderID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getProducsByOrder(resp.result));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
};

export const loadOrderHistory = (orderId, ItemId, setTableLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/GETBYID_History/${orderId}/${ItemId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getOrderHistory(resp.result));
          setTableLoading(false);
        } else {
          dispatch(getOrderHistory([]));
          setTableLoading(false);
        }
      });
  };
};

export const loadUpdateOrderQty = (updateRequest, pageRefresh, status) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/OrderManagement/UpdateOrders`, updateRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateOrderQty());
          if (status) {
            swal.showAlert(
              "Success",
              "To Be Dispatched quantity has successfully sent",
              "success"
            );
          }
          pageRefresh();
        } else {
          swal.showAlert("Fail", resp.errorMessage, "warning");
        }
      })
      .catch((err) => {});
  };
};

// Orders Page

// Meeting Page
export const loadMeetingData = () => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Meeting/Get`, HeaderAuthentication.getRequestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getMeetingData(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadMeetingCountData = (date, filterUserId, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Meeting/GetMeetingCounts?FromDate=${date}&ToDate=${date}&UserId=${filterUserId}&ManagerID=`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getMeetingCounts(resp.result));
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadMeetingPurposeData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/MeetingPurpose/BindPurposeDropdown?status=${1}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getMeetingPurposeData(
            resp.result.map((data) => {
              return { label: data.purpose, value: data.purposeId };
            })
          )
        );
      })
      .catch((err) => {});
  };
};
// Meeting Page

// Product Category Page
export const loadProdCategoryDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Category/BindParentCategoryDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getProdCategoryDrop(
            resp.result.map((category) => {
              return { label: category.name, value: category.categoryID };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadProdSubCategory = (ParentCategoryID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Category/BindSubCategoryDropDown/${ParentCategoryID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getProdSubCategory(
            resp.result.map((subCategory) => {
              return {
                label: subCategory.name,
                value: subCategory.categoryID,
                parent: subCategory.parentCategoryID,
              };
            })
          )
        );
      });
  };
};
// Product Category Page

// Designation page
export const loadDesignationDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Designation/BindDesignationDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getDesignationDrop(
            resp.result
              .filter((desig) => desig.designationLevel !== 1)
              .map((designation) => {
                return {
                  label: designation.designationName,
                  value: designation.designationID,
                  level: designation.designationLevel,
                };
              })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadDepasrtDesignation = (departID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Designation/Get?DepartmentID=${departID}&PageSize=1000&CurrentPage=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getdepartDesignation(
            resp.result.map((desigantion) => {
              return {
                label: desigantion.designationName,
                value: desigantion.designationID,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};
// Designation page

// Basefare page
// mot dropdown
export const loadMotDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Mot/BindMotDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getMotDrop(
            resp.result.map((mot) => {
              return { label: mot.motName, value: mot.motID, type: mot.type };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

// mot dropdown
export const loadBaseFare = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/CustomeBaseFare/Get?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getToataldataCount(resp.recordCount[0]));
        dispatch(getBaseFare(resp.result));
        setLoading(false);
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const AddbaseFare = (postRequest, closeAddModal, lastBaseFare) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeBaseFare/AddCustomeBaseFare`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (!resp.success) {
          // closeAddModal();
          // swal.showAlert("Fails", "Something went wrong...!", "warning");
          toast.error(resp.errorMessage);
        } else {
          if (lastBaseFare) {
            swal.showAlert(
              "Success",
              "Basefare Added successfully...!",
              "success"
            );
          }
          dispatch(addBadseFareTolist());
          closeAddModal();
        }
      })
      .catch((err) => {
        toast.error(`${err}`);
      });
  };
};

export const loadSingleBaseFare = (baseId) => {
  return function (dispatch) {
    if (baseId == null) {
      dispatch(getSingleBasefare({}));
    } else {
      fetch(
        `${baseURL}/api/CustomeBaseFare/Get/${baseId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(getSingleBasefare(resp.result));
        })
        .catch((err) => {});
    }
  };
};

export const loadUpdateBasefare = (
  requestOption,
  closeAddModal,
  lastBaseFare
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeBaseFare/UpdateCustomeBaseFare`, requestOption)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          closeAddModal();
          swal.showAlert("Fails", "Something went wrong...!", "failure");
        } else {
          closeAddModal();
          dispatch(updateBasefare());
          if (lastBaseFare) {
            swal.showAlert(
              "Success",
              "Basefare Details Updated successfully...!",
              "success"
            );
          }
        }
      })
      .catch((err) => {});
  };
};
// Basefare page

// Department page
export const loadDepartmentDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Department/BindDepartmentDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getDepartmentDrop(
            resp.result.map((data) => {
              return {
                value: data.departmentID,
                label: data.departmentName,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};
// Department page

// Get Apis for CountyCodes, Zone, country, State, City
export const loadCountryCodes = () => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Zone/BindCountryCodeDropDown`, {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: JSON.stringify({
          subscriptionId: "SUB0000000001",
          userId: "USR001",
          roleId: "1",
        }),
      },
    })
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getCountryCodes(
            resp.result
              .filter((item) => item.code !== "" && item.code !== null)
              .map((data) => {
                return { value: data.code, label: data.code };
              })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadZoneList = (setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetZones`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getZoneList(
            resp.result.map((data) => {
              return { value: data.zoneId, label: data.zoneName };
            })
          )
        );
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};

export const loadStateList = (zoneId) => {
  return function (dispatch) {
    if (zoneId) {
      fetch(
        `${baseURL}/api/Zone/GetStates?ZoneId=${zoneId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(
            getStateList(
              resp.result.map((data) => {
                return { value: data.stateId, label: data.stateName };
              })
            )
          );
          // setIsLoading(false)
        })
        .catch((err) => {});
    } else {
      fetch(
        `${baseURL}/api/Zone/GetStates`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(
            getStateList(
              resp.result.map((data) => {
                return { value: data.stateId, label: data.stateName };
              })
            )
          );
          // setIsLoading(false)
        })
        .catch((err) => {});
    }
  };
};

export const loadCityList = (stateId) => {
  return function (dispatch) {
    if (stateId) {
      fetch(
        `${baseURL}/api/Zone/GetCities?StateId=${stateId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(
            getCityList(
              resp.result.map((data) => {
                return { value: data.cityId, label: data.cityName };
              })
            )
          );
          // setIsLoading(false)
        })
        .catch((err) => {});
    } else {
      fetch(
        `${baseURL}/api/Zone/GetCities`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(
            getCityList(
              resp.result.map((data) => {
                return { value: data.cityId, label: data.cityName };
              })
            )
          );
          // setIsLoading(false)
        })
        .catch((err) => {});
    }
  };
};
// Get Apis for Zone, country, State, City

// Upload Images
export const loadUploadSingleImage = (
  postRequest,
  postData,
  closeModal,
  actionType,
  customFields,
  clientId
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let imgUrl = resp.result[0];
          postData.logo = imgUrl;
          const clientDetails = {
            client: postData,
          };
          // console.log(postData)
          let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(clientDetails),
          };
          if (actionType === "post-data") {
            dispatch(
              loadPostCompanyData(requestOption, closeModal, customFields)
            );
          } else if (actionType === "update-data") {
            dispatch(
              loadUpdateCompanyData(
                requestOption,
                closeModal,
                customFields,
                clientId
              )
            );
          }
        } else {
          toast.error(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};

export const loadUploadProductImages = (
  postUploadData,
  imgFile,
  productData,
  productId,
  closeModel,
  sentGalImages
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, postUploadData)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let mainImage;
          let galleryImages;
          let stringLinks = [];
          if (imgFile) {
            mainImage = resp.result[0].toString();
            galleryImages = resp.result.slice(1);
            galleryImages.map((image) => {
              return stringLinks.push(image);
            });
          } else {
            if (!productData.image) {
              mainImage = "";
            } else {
              mainImage = productData.image;
            }
            galleryImages = resp.result;
            galleryImages.map((image) => {
              return stringLinks.push(image);
            });
          }

          productData.image = mainImage;
          productData.galaryImage = "";
          let uploadImages = [...sentGalImages, ...stringLinks];
          productData.galaryImages = uploadImages;
          let postProduct = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(productData),
          };
          if (productId) {
            dispatch(loadUpdateProduct(postProduct, closeModel));
          } else {
            dispatch(loadPostProduct(postProduct, closeModel));
          }
        } else {
          toast.error(`${resp.errorMessage}`);
        }
      })
      .catch((error) => {
        console.log("ERROR:", error);
      });
  };
};
// Upload Images

// Super admin pages
// Google credits page
export const loadGoogleCredits = (
  Flag,
  setIsLoading,
  PageSize,
  currentPage,
  filterArray,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }

    fetch(
      `${baseURL}/api/HitCount/GetApiKeyHitAndCreaditsCount?ByFlag=${Flag}${param}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          if (Flag === "GetGoogleApiKeyCredits") {
            dispatch(getGoogleCredits(resp.result));
            dispatch(getToataldataCount(resp.recordCount[0]));
            setLoading(false);

            dispatch(
              getApiNameDrop(
                resp.result.map((data, i) => {
                  return { value: i, label: data.GoogleApiKey };
                })
              )
            );
          } else if (Flag === "GetApiCredits") {
            dispatch(getToataldataCount(resp.recordCount[0]));
            dispatch(getAPICredits(resp.result));
            setIsLoading(false);
            setLoading(false);
            dispatch(
              getGoogleApiNameDropdown(
                resp.result.map((data) => {
                  return { value: data.ApiName, label: data.ApiName };
                })
              )
            );
          } else if (Flag === "googleapikeyhitcountbysub") {
            dispatch(getGoogleApiHitCount(resp.result));
          } else if (Flag === "GetException") {
            dispatch(getExceptionData(resp.result));
          } else if (Flag === "GetMobileException") {
            dispatch(getMobileExceptionData(resp.result));
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadCreditsCount = (Flag, PageSize, currentPage) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/HitCount/GetApiKeyHitAndCreaditsCount?ByFlag=${Flag}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getCreditCount(resp.result));
        }
      })
      .catch((err) => {});
  };
};

export const loadPostGoggleApis = (postRequest, modalClose, googleCreditId) => {
  return function (dispatch) {
    if (googleCreditId) {
      fetch(`${baseURL}/api/HitCount/UpdateGoogleCreditApi`, postRequest)
        .then((resp) => {
          return resp.json();
        })
        .then((resp) => {
          if (resp.success) {
            dispatch(addGoogleCredits());
            modalClose();
            swal.showAlert(
              "Success",
              "Google API Credits Updated successfully...!",
              "success"
            );
          } else {
            modalClose();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    } else {
      fetch(`${baseURL}/api/HitCount/AddGoogle_CreditApi`, postRequest)
        .then((resp) => {
          return resp.json();
        })
        .then((resp) => {
          if (resp.success) {
            dispatch(addGoogleCredits());
            modalClose();
            swal.showAlert(
              "Success",
              "Google API Credits Created successfully...!",
              "success"
            );
          } else {
            modalClose();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    }
  };
};

export const loadPostApiCredits = (postData, closeAddModal, apiDataId) => {
  return function (dispatch) {
    if (apiDataId) {
      fetch(`${baseURL}/api/HitCount/UpdateApiCredits`, postData)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postAPICredits());
            closeAddModal();
            swal.showAlert(
              "Success",
              "API Credits Updated successfully...!",
              "success"
            );
          } else {
            closeAddModal();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    } else {
      fetch(`${baseURL}/api/HitCount/AddApiCredits`, postData)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postAPICredits());
            closeAddModal();
            swal.showAlert(
              "Success",
              "API Credits Added successfully...!",
              "success"
            );
          } else {
            closeAddModal();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    }
  };
};

export const loadSingleApiData = (apiId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/HitCount/GetApiCreadits/${apiId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleApiData(resp.result));
        }
      })
      .catch((err) => {});
  };
};

// export const loadGoogleApiNameDropdown = () => {
//   return function(dispatch){
//     fetch(`${baseURL}/api/HitCount/BindApiCreditsDropDown`, HeaderAuthentication.getRequestOptions)
//     .then(resp => resp.json())
//     .then(resp => {
//       console.log(resp.result, "asadsdaf")
//       if(resp.success){
//         dispatch(getGoogleApiNameDropdown(resp.result.map((data) => {
//           return { value: data.apiCreditsID, label: data.apiCreditsName };
//         })))
//       }
//     })
//   }
// }
// Google credits page

export const loadGetOTP = (postRequest) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Authentication/OTPPassword`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let messageArray = resp.message.split("|");
          dispatch(getOtp(messageArray[1]));
        }
      })
      .catch((err) => {});
  };
};

// Leads Data
export const loadLeadsCardData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscriptionCountsSuperAdmTOP`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getLeadsCardDetails(res.result));
      })
      .catch((err) => {});
  };
};
export const loadLeadsTableData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscriptionCountsSuperAdmTBL`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getLeadsTableDetails(res.result));
      })
      .catch((err) => {});
  };
};
export const loadLeadGenSubReportTableData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Essae/Getsubscrptioncount`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getLeadGenSubScriptionReportTableDetails(res.result));
      })
      .catch((err) => {});
  };
};
// Leads Data
// Super admin pages

// User Page
export const loadUserDropdown = (multiSelect) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/User/BindUsersDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getUserDropdown(
              resp.result
                .filter((item) => item.isAdmin !== 1)
                .map((data) => {
                  if (multiSelect) {
                    return data;
                  } else {
                    return { value: data.userId, label: data.userFullName };
                  }
                })
            )
          );
        }
      });
  };
};

export const loadUserDropdownByManager = (managerID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subordinates/BindUsersDropDown/${managerID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getUserDropdownByManager(
              resp.result
                .filter((item) => item.isAdmin !== 1)
                .map((data) => {
                  return { value: data.userId, label: data.userFullName };
                })
            )
          );
        }
      });
  };
};

export const loadUserByClient = (clientId) => {
  return function (dispatch) {
    // fetch(`${baseURL}/api/Client/GetUserListByClient?ClientID=${clientId}`, HeaderAuthentication.getRequestOptions)
    fetch(
      `${baseURL}/api/Client/GetClientMapping/${clientId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getUserDropdownByCompany(
              resp.result.map((data) => {
                return { value: data.userID, label: data.userFullName };
              })
            )
          );
        }
      })
      .catch((err) => {});
  };
};

export const loadUserRegion = (userId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetUserRegionsResult${
        userId !== undefined && userId !== "" ? `?userId=${userId}` : `/`
      }`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getUserRegionResult(resp.result));
        }
      });
  };
};

export const loadAllUserDetails = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/User/UserDetails?EnableStatus=1&PageSize=1000&CurrentPage=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getAllUserDetails(resp.result));
        }
      });
  };
};

// User Page

// Leave Page

export const loadLeaveTypeDropdown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Leave/BindLeaveTypes`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getLeaveTypeDropdown(
              resp.result.map((data) => {
                return { value: data.leaveTypeID, label: data.leaveTypeName };
              })
            )
          );
        }
      });
  };
};

// Leave Page

export const loadManagerDropdown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/User/BindManagerDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getManagerDropdown(
              resp.result.map((data) => {
                return { value: data.userId, label: data.userFullName };
              })
            )
          );
        }
      });
  };
};

// Google Hit Count
// export const loadHitCount = () => {
//   return function(dispatch){
//     fetch(`${baseURL}`)
//   }
// }
// Google Hit Count

// Subscription Pages
export const loadSubscriptionDropdown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/BindSubscriptionTypeDropdown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getSubscriptionDrop(
            resp.result.map((subscription) => {
              return {
                label: subscription.subscriptionName,
                value: subscription.subscriptionTypeID,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadUpdateSubscription = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Subscription/UpdateSubscription`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateSubscription());
          closeModal();
          swal.showAlert(
            "Success",
            "Subscription Updated Successfully",
            "success"
          );
        } else {
          swal.showAlert("Fails", "Something Went Wrong...!", "warning");
        }
      })
      .catch((err) => {});
  };
};
// Subscription Pages

// Refferal Pages
// export const loadRefferalData = (setIsLoading) => {
//   return function (dispatch) {
//     fetch(`${baseURL}/api/SalesBeeReferel/Get?Status=1`, HeaderAuthentication.getRequestOptions)
//       .then((resp) => resp.json())
//       .then((resp) => {
//         dispatch(getSalesBeeRefferal(resp.result));
//         // if (setIsLoading) {
//         //   setIsLoading(false);
//         // }
//       })
//       .catch((err) => {});
//   };
// };
export const addRefferalData = (postData, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/SalesBeeReferel/AddSalesBeeReferel`, postData)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.result) {
          dispatch(postSalesBeeRefferal());
          closeAddModal();
          swal.showAlert("Success", "Added successfully", "success");
        } else {
          swal.showAlert("Error", "Failed to add", "error");
        }
      })
      .catch((err) => {});
  };
};
export const loadUpdateSalesBeeRefferal = (requestOption, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/SalesBeeReferel/UpdateSalesBeeReferel`, requestOption)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fails", "Something went wrong...!", "failure");
        } else {
          closeAddModal();
          dispatch(upDateSalesBeeRefferal());
          swal.showAlert(
            "Success",
            "Refferal Details Updated successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadSingleRefferalData = (refId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SalesBeeReferel/Get/${refId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleRefferalData(resp.result));
        }
      })
      .catch((err) => {});
  };
};

export const loadFilterReferalData = (
  filterArray,
  PageSize,
  currentPage,
  setIsLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/SalesBeeReferel/Get?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSalesBeeRefferal(resp.result));
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};
// Refferal Pages

// MapClientCategory
export const loadMapClientCategory = (
  filterArray,
  PageSize,
  currentPage,
  setIsLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/ClientCategoryMapping/Get?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getMapClientCategory(resp.result));
        setIsLoading(false);
      });
  };
};
export const addClientCategoryMapping = (postData, closeAddModal) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategoryMapping/AddClientCategoryMapping`,
      postData
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.result) {
          dispatch(postClientCategoryMapping());
          closeAddModal();
          swal.showAlert("Success", "Added Successfully", "Success");
        } else {
          swal.showAlert("Failed", "Failed to add", "Failed");
        }
      });
  };
};
// MapClientCategory

// Hierarcy Page
export const loadDesignationHierarchy = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Designation/GetDesignationTree`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getHierarchyData(resp.result));
      });
  };
};
export const loadUserDetailsHierarchy = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/User/UserDetailsTree`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getHierarchyData(resp.result));
      });
  };
};

// Hierarcy Page

// ProductFilter Page
export const postProductFilterData = (postData, setEditMode) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ProductPricing/BulkManageProductPricing`, postData)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.success) {
          console.log(res.result);
          dispatch(postProductFilter());
          swal.showAlert("Success", "Added Successfully", "Success");
          setEditMode(false);
        } else {
          // swal.showAlert("Failed", "Failed to add", "Failed");
          toast.error(res.errorMessage);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        // swal.showAlert("Fail", `${error}`, "warning");
        toast.error(`${error}`);
      });
  };
};
export const loadProductFilterData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ProductPricing/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          console.log(resp.result, "resp.success");
          dispatch(getProductFilterData(resp.result));
        }
      });
  };
};
export const loadSingleProductFilterData = (productId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ProductPricing/Get/{ProductPricingID}/${productId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleProductFilterData(resp.result));
        }
      })
      .catch((err) => {});
  };
};
export const loadUpdateProductPricingData = (requestOption) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ProductPricing/UpdateProductPricing`, requestOption)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fails", "Something went wrong...!", "failure");
        } else {
          // closeAddModal();
          dispatch(updateProductFilterData());
          swal.showAlert(
            "Success",
            "Product Pricing Data Updated successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
// ProductFilter Page

// UserReferrals Page
export const loadUserReferralsData = (
  filterArray,
  PageSize,
  currentPage,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    if (PageSize || currentPage) {
      let param = "";
      if (filterArray.length !== 0) {
        filterArray.map((item, i) => {
          return (param = param + "&" + item.key + "=" + item.value);
        });
      }
      fetch(
        `${baseURL}/api/GetFastReferelProfile/Get?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(getUserReferralsData(resp.result));
          dispatch(getToataldataCount(resp.recordCount[0]));
          if (setIsLoading) {
            setIsLoading(false);
          }
          setLoading(false);
        });
      return;
    }
    fetch(
      `${baseURL}/api/GetFastReferelProfile/Get?PageSize=1000&CurrentPage=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getUserReferralsDataDropDOwn(resp.result));
      });
  };
};
export const addUserBankDetails = (
  postData,
  closeAddModal,
  gfReferelProfileId
) => {
  return function (dispatch) {
    if (gfReferelProfileId) {
      fetch(
        `${baseURL}/api/GetFastRefPaymentDetails/UpdateGetFastRefPaymentDetails`,
        postData
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.success) {
            dispatch(postUserBankDetailsData());
            closeAddModal();
            swal.showAlert("Success", "updated Successfully", "success");
          } else {
            closeAddModal();
            swal.showAlert("Failed", "Failed to update", "Failed");
          }
        });
    } else {
      fetch(
        `${baseURL}/api/GetFastRefPaymentDetails/AddGetFastRefPaymentDetails`,
        postData
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.success) {
            dispatch(postUserBankDetailsData());
            closeAddModal();
            swal.showAlert("Success", "Added Successfully", "success");
          } else {
            closeAddModal();
            swal.showAlert("Failed", "Failed to add", "Failed");
          }
        });
    }
  };
};

export const loadSingleUserRefferalData = (refId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastRefPaymentDetails/Get?GFRefProfileID=${refId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleUserReferralsData(resp.result));
        } else {
          dispatch(getSingleUserReferralsData({}));
        }
      })
      .catch((err) => {});
  };
};

export const loadUserReferralsDropDown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastReferelProfile/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getUserReferralsDropDown(
            resp.result.map((refData) => {
              return {
                value: refData.addedByID,
                label: refData.userName,
              };
            })
          )
        );
        // setIsLoading(false);
      });
  };
};
// UserReferrals Page

// Referral Product Page
export const loadProductReferralData = (setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ReferelQr/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getProductReferralData(resp.result));
        setIsLoading(false);
      });
  };
};
// Referral Product Page

// Commission Page
export const loadProductDropdownData = (filterArray, PageSize, currentPage) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    // const productID = "";
    fetch(
      `https://www.getfaststore.com//api/product/PostProducts?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.postTravelizeAuth,
        body: JSON.stringify({ productID: "" }),
      }
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getProductDropdownData(resp.ProductList));
      });
  };
};

export const loadSingleCommissionData = (commissionId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastRefProdCommition/Get/${commissionId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getSingleCommissionData(resp.result));
      });
  };
};

export const addCommissionData = (
  postData,
  closeAddModal,
  referelProfileId
) => {
  return function (dispatch) {
    if (referelProfileId) {
      fetch(
        `${baseURL}/api/GetFastRefProdCommition/UpdateGFRefProdCommition`,
        postData
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.success) {
            dispatch(postCommissionData());
            closeAddModal();
            swal.showAlert("Success", "updated Successfully", "success");
          } else {
            closeAddModal();
            swal.showAlert("Failed", "Failed to update", "Failed");
          }
        });
    } else {
      fetch(
        `${baseURL}/api/GetFastRefProdCommition/AddGFRefProdCommition`,
        postData
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.success) {
            dispatch(postCommissionData());
            closeAddModal();
            swal.showAlert("Success", "Added Successfully", "success");
          } else {
            closeAddModal();
            swal.showAlert("Failed", "Failed to add", "Failed");
          }
        });
    }
  };
};

export const loadCommissionData = (
  filterArray,
  PageSize,
  currentPage,
  setTotalCounts,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/GetFastRefProdCommition/Get?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCommissionData(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        setTotalCounts(resp.recordCount);
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };
};
// Commission Page

// Passbook Page
export const loadPassbookCardData = (
  filterArray,
  PageSize,
  currentPage,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        if (i === 0 && !param) {
          return (param = item.key + "=" + item.value);
        } else {
          return (param = param + "&" + item.key + "=" + item.value);
        }
      });
    }
    fetch(
      `${baseURL}/api/GetFastRefPassbook/GetStatistic?${param}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getPassbookCardDetails(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };
};

export const loadTransactionSummary = (GFRefProfileID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastReferelTransSummary/Get?GFRefProfileID=${GFRefProfileID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getTransactionSummary(resp.result));
      });
  };
};

export const loadUserBankData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastRefPaymentDetails/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getUserBankData(resp.result));
      });
  };
};

export const loadUserPassbookDetails = (summaryId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastReferelTransSummary/Get?gfRefTransSummaryID=${summaryId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getUserPassbookDetails(resp.result));
      });
  };
};

export const loadPassbookSummeryData = (
  filterArray,
  PageSize,
  currentPage,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/GetFastRefPassbook/Get?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getPassbookSummeryData(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };
};
export const loadPassbookSummeryDataByProfile = (
  GFRefProfileID,
  PageSize,
  currentPage,
  setIsLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastRefPassbook/Get?GFRefProfileID=${GFRefProfileID}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getPassbookSummeryDataByProfile(resp.result));
        if (setIsLoading) {
          setIsLoading(false);
        }
      });
  };
};

export const loadUserTransactionDetails = (summaryId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastReferelTransDetails/Get/${summaryId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getUserTransactionDetails(resp.result));
      });
  };
};
// Passbook Page

// Payment Page
export const loadPaymentData = (
  filterArray,
  PageSize,
  currentPage,
  setIsLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/GetFastRefPassbook/GetWithdrawRequests?PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        setIsLoading(false);
        dispatch(getPaymentData(resp.result));
      });
  };
};
export const loadSinglePaymentData = (gfPassId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/GetFastRefPassbook/Get/${gfPassId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getSinglePaymentData(resp.result));
      });
  };
};
export const addPaymentData = (postData, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/GetFastRefPassbook/AddGFRefPassbook`, postData)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          dispatch(postPaymentData());
          closeAddModal();
          swal.showAlert("Success", "Added Successfully", "success");
        } else {
          closeAddModal();
          swal.showAlert("Failed", "Failed to add", "Failed");
        }
      });
  };
};
// Payment Page

// SignupSubscription Page
export const loadSignupSubscriptionData = (sbctptnId, roleId, authToken) => {
  return function (dispatch) {
    let authenticaton = HeaderAuthentication.getRequestOptions;
    if (authToken) {
      authenticaton = authToken;
    }
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?SubscriptionID=${sbctptnId}&RoleID=${roleId}`,
      authenticaton
    )
      .then((resp) => resp.json())
      .then((resp) => {
        console.log(resp, "resp");
        dispatch(getSignupSubscriptionData(resp.result));
      })
      .catch((err) => {});
  };
};
// SignupSubscription Page

// CreateSubscription Page
export const loadReferredByDropdowndata = () => {
  return function (dispatch) {
    fetch(
      `https://backend.leadmerge.com/api/fetch_bde_leadgen_presales`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(
          getReferredByDropdownData(
            resp.data.map((referred) => {
              return { label: referred.name, value: referred.id };
            })
          )
        );
      });
  };
};
// CreateSubscription Page

// Payrole Page
export const loadPayrollData = (userId) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  return function (dispatch) {
    fetch(
      `${baseURL}/api/UserPayrol/GeneratePayrole?UserID=${userId}&Year=${currentYear}&Month=${currentMonth}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getPayrollData(resp.result));
      })
      .catch((error) => {
        console.error("Error while posting employee data:", error);
        swal.showAlert(
          "Error",
          "Failed to add. Please try again later.",
          "error"
        );
      });
  };
};
export const postEmploeePayrollData = (postData, fetchPayrollData) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/PayrolSalaryBreakUp/AddSalaryBreakUp`, postData)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          dispatch(postEmployeePayroll());
          fetchPayrollData();
          swal.showAlert("Success", "Added Successfully", "success");
        } else {
          swal.showAlert("Failed", "Failed to add", "Failed");
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const { Success, Message, ErrorMessage } = error.response.data;

          if (Success === false) {
            console.error("API error:", Message, ErrorMessage);
          }
        }
      });
  };
};
export const postEmployeeFixedSalaryData = (postData) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/UserMonthlyFixedIncome/AddUserMonthlyFixedIncome`,
      postData
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.success) {
          dispatch(postEmployeeFixedSalary());
          swal.showAlert("Success", "Added Successfully", "success");
        } else {
          swal.showAlert("Failed", "Failed to add", "Failed");
        }
      });
  };
};
export const deleteEmployeePayrollData = (postData, fetchPayrollData) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/PayrolSalaryBreakUp/DeleteSalaryBreakUp`, postData)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          dispatch(deleteEmployeePayroll());
          fetchPayrollData();
          swal.showAlert("Success", "Deleted Successfully", "success");
        } else {
          swal.showAlert("Failed", "Failed to Delete", "Failed");
        }
      });
  };
};
// Payrole Page

// Region Page
export const loadCountryData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/BindCountryDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(
          getCountryName(
            resp.result.map((data) => {
              return { value: data.countryID, label: data.countryName };
            })
          )
        );
      });
  };
};

export const loadDropdownStateName = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetStates`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getDropdownStateName(
            resp.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          )
        );
        // setIsLoading(false)
      })
      .catch((err) => {});
  };
};

export const loadAllStateList = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetStates`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getAllStateList(resp.result));
        // setIsLoading(false)
      })
      .catch((err) => {});
  };
};
export const loadAllCityList = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/Zone/GetCities?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getToataldataCount(resp.recordCount[0]));
        dispatch(getAllCityList(resp.result));
        setLoading(false);
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};
export const addStateName = (postData, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Zone/AddStates`, postData)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          dispatch(postStateName());
          closeAddModal();
          swal.showAlert("Success", "Added Successfully", "success");
        } else {
          closeAddModal();
          swal.showAlert("Failed", "Failed to add", "Failed");
        }
      });
  };
};
export const addCityName = (postData, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Zone/AddCities`, postData)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          dispatch(postCityName());
          closeAddModal();
          swal.showAlert("Success", "Added Successfully", "success");
        } else {
          closeAddModal();
          swal.showAlert("Failed", "Failed to add", "Failed");
        }
      });
  };
};
// Region Page

// OrthoAttendence page
export const loadOrthoAttendence = () =>
  // setIsLoading
  {
    return function (dispatch) {
      const postTravelizeAuth = {
        "Content-Type": "application/json",
        Travelize_Authentication:
          "P/xgewt8+bhG6xYVveE+lL7hYq5R2vH4yn8KA2Yl0haAaG2u/l8X/HRUQEQfjCnjzsfxEScy8EzAwj6vFlYlt9aqHD3Vydx/FisAdPWn3jl/hc3PeVkiLSF/YIA2VD4hrSM0rLvSsXC8wBkk/U4/WhP2EZvgadxtAlJr4pdT0ZL3uXcFjMiaqlPKATqdpCduM81mWcouA7MWLxW1KhS2i3e23bL4eC3XytaUv/o37Js2GvVECoGKnanxSPYmakmBOpOHC9d0TnqsSWT1N4RNQZQnntR1JVsZF2C7wV/Bl0ktO1u460v1VK2G4rhwMlA1Bo9wy7VsVlTS6NmBbXbmRA==",
      };
      fetch(`${baseURL}/api/Meeting/WP_flash_orthoAttendance`, {
        headers: postTravelizeAuth,
        method: "GET",
        mode: "cors",
      })
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(getOrthoAttendence(resp.result));
          // setIsLoading(false)
        })
        .catch((err) => {});
    };
  };
// OrthoAttendence page

// TravelReport Page
export const loadTravelReportAttachments = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    // fetch(`${baseURL}/api/Claim/Get?UserID=USR026&FromDate=01/09/2023&ToDate=17/01/2024&StatusID=42&SearchStatusType=Claim`, HeaderAuthentication.getRequestOptions)
    fetch(
      `${baseURL}/api/Claim/GetTravelReport?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(getTravelAttachmentDetails(res.result));
        dispatch(getToataldataCount(res.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };
};

export const loadSingleTravelData = (
  clientId,
  filterArray,
  currentPage,
  PageSize
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray && Array.isArray(filterArray) && filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return (param = param + "&" + item.key + "=" + item.value);
      });
    }
    fetch(
      `${baseURL}/api/Claim/GetTravelReport?claimID=${clientId}&CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getSingleTravelReportData(resp.result));
      });
  };
};
// TravelReport Page

// Route Deviation Page
export const loadRouteDeviationData = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  let param = "";
  if (filterArray.length !== 0) {
    filterArray.map((item, i) => {
      return (param = param + "&" + item.key + "=" + item.value);
    });
  }
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Route/GetRouteClientDivitions?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getRouteDeviationData(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };
};
// Route Deviation Page

// Claim Hirarchy Page
export const loadClaimHirarchyData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClaimAprHierarchy/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        dispatch(getClaimHirarchyData(resp.result));
      });
  };
};
// Claim Hirarchy Page

// Kpa Page
export const loadKpaData = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  let param = "";
  if (filterArray.length !== 0) {
    filterArray.map((item, i) => {
      return (param = param + "&" + item.key + "=" + item.value);
    });
  }
  return function (dispatch) {
    fetch(
      `${baseURL}/api/KPA/Get?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getKpaData(res.result));
        dispatch(getToataldataCount(res.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      });
  };
};

export const postKpaData = (postData, closeAddModal, kpaId) => {
  return function (dispatch) {
    if (kpaId) {
      fetch(`${baseURL}/api/KPA/UpdateKPA`, postData)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.success) {
            dispatch(addKpaData());
            closeAddModal();
            swal.showAlert("Success", "Updated Successfully", "Success");
          } else {
            closeAddModal();
            swal.showAlert("Failed", "Failed to Update", "Failed");
          }
        });
    } else {
      fetch(`${baseURL}/api/KPA/AddKPA`, postData)
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.success) {
            dispatch(addKpaData());
            closeAddModal();
            swal.showAlert("Success", "Added Successfully", "Success");
          } else {
            closeAddModal();
            swal.showAlert("Failed", "Failed to Add", "Failed");
          }
        });
    }
  };
};
export const loadSingleKpaData = (kpaId) => {
  return function (dispatch) {
    if (kpaId) {
      fetch(
        `${baseURL}/api/KPA/Get/${kpaId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          dispatch(getSingleKpa(res.result));
        });
    }
  };
};
// Kpa Page

// Account Settings Page
export const AddDirectVisitData = (postData) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Subscription/UpdateDirectVisit_InTime`, postData)
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message, { autoClose: 5000 });
          dispatch(postDirectVisitData());
        } else {
          toast.error(resp.errorMessage, { autoClose: 6000 });
        }
      });
  };
};
export const AddCheckOutData = (postData) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/UpdateBaseLocationCheckOutRadius`,
      postData
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message, { autoClose: 5000 });
          dispatch(postCheckOutData());
        } else {
          toast.error(resp.errorMessage, { autoClose: 6000 });
        }
      });
  };
};
export const AddCheckInData = (postData) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/UpdateBaseLocationCheckInRadius`,
      postData
    )
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.success) {
          dispatch(postCheckInData());
          toast.success(resp.message, { autoClose: 5000 });
        } else {
          toast.error(resp.errorMessage, { autoClose: 6000 });
        }
      });
  };
};
// Account Settings Page

// Machines Page
export const loadMachinesData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Essae/GetMachine`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getMachines(res.result));
      });
  };
};
export const addMachinesData = (postData, closeModel) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Essae/PostMachine`, postData)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.success) {
          dispatch(addMachines());
          closeModel();
          swal.showAlert("Success", "Added Successfully", "Success");
        } else {
          closeModel();
          swal.showAlert("Failed", "Failed to Add", "Failed");
        }
      });
  };
};
export const loadSingleMachine = (machineId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Essae/GetMachine?machineCode=${machineId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getSingleMachine(res.result));
      })
      .catch(() => {
        console.log("Error");
      });
  };
};
// Machines Page
// AnnualContract Page
export const loadAmcData = () => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Essae/GetAMC`, HeaderAuthentication.getRequestOptions)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getAmc(res.result));
      })
      .catch(() => {
        console.log("Error in Fetching AMC Data");
      });
  };
};
export const addAMCData = (postData, closeModel) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Essae/PostAMC`, postData)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.success) {
          dispatch(postAmc());
          closeModel();
          swal.showAlert("Success", "Added Successfully", "Success");
        } else {
          closeModel();
          swal.showAlert("Failed", "Failed to Add", "Failed");
        }
      });
  };
};
export const loadSingleAmcData = (amcNum) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Essae/GetAMC?AMCNumber=${amcNum}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getSingleAmc(res.result));
      })
      .catch(() => {
        console.log("Error In Getting Single Amc data");
      });
  };
};
// AnnualContract Page
// Escalation Page
export const loadEscalationData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Essae/GetEscalation`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getEscalation(res.result));
      })
      .catch(() => {
        console.log("error");
      });
  };
};
export const addEscalationData = (postData, closeModel) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Essae/PostEscalation`, postData)
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.success) {
          dispatch(postEscalation());
          closeModel();
          swal.showAlert("Success", "Added Successfully", "Success");
        } else {
          closeModel();
          swal.showAlert("Failed", "Failed to Add", "Failed");
        }
      });
  };
};
// Escalation Page

// User Activity Page
export const loadUserActivityData = (
  filterUserId,
  date,
  PageSize,
  currentPage
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Activity/GetActivities?userId=${filterUserId}&Date=${date}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        dispatch(getUserActivityData(res.result.reverse()));
      });
  };
};
// User Activity Page

export const addCustomeFieldsForCompany = (postData, closeModel) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFieldValue/BulkManageCustomeFieldValue`,
      postData
    )
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        if (res.success) {
          dispatch(postCustomeFieldsForCompany());
          closeModel();
          swal.showAlert("Success", "Added Successfully", "Success");
        } else {
          closeModel();
          swal.showAlert("Failed", "Failed to Add", "Failed");
        }
      });
  };
};

export const loadCustomFieldsForCompany = (id) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFields/Get?CustomePagesID=${id}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCustomeFieldsForCompany(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadSingleCustomFieldsForCompany = (clientID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFieldValue/Get?tableID=${clientID}&customePageID=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleCustomeFieldsForCompany(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadUploadCustomImage = (
  postRequest,
  customFields,
  closeModal,
  customeLocalImage
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let imgUrl = resp.result[0];
          const updatedArray = customFields.map((item) => {
            if (item.customeFieldID === customeLocalImage.fieldId) {
              return { ...item, value: imgUrl };
            }
            return item;
          });

          let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(updatedArray),
          };

          dispatch(addCustomeFieldsForCompany(requestOption, closeModal));
        } else {
          console.log(resp, "error resone");
        }
      })
      .catch((err) => {});
  };
};

// Service call for pages end

// VHD MODULE
export const loadAllVendorDetails = (
  PageSize,
  currentPage,
  stateID,
  cityID,
  activeStatus,
  searchValue
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/VHD/GetALLVHD?PageSize=${PageSize}&CurrentPage=${currentPage}&EnableStatus=${activeStatus}&City=${cityID}&State=${stateID}&Text=${searchValue}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(fetchAllVendorDetails(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadAllBranchDetails = (
  PageSize,
  currentPage,
  zoneID,
  stateID,
  cityID,
  activeStatus,
  searchValue
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/VHD/GetALLBRANCH?PageSize=${PageSize}&CurrentPage=${currentPage}&EnableStatus=${activeStatus}&City=${cityID}&State=${stateID}&Zone=${zoneID}&Name=${searchValue}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(fetchAllBranchDetails(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadAllPODetails = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/VHD/GetALLPO?PageSize=50&CurrentPage=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(fetchAllPODetails(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadAllInvoiceDetails = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/VHD/GetALLInvoices?PageSize=50&CurrentPage=1`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(fetchAllInvDetails(resp.result));
      })
      .catch((err) => {});
  };
};
// VHD MODULE,
