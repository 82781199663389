import React, { useState, useEffect, useMemo, useRef } from "react";
import AddEditFormComponent from "../../ReUseComponents/AddEditFormComponent";
import { FloatingLabel, Form } from "react-bootstrap";
import Select from "react-select";
import * as swal from "../../Consturl/SwalAlert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { MiCustomBtn, SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import DatePicker from "react-datepicker";
import { baseURL } from "../../BaseUrl";
import { loginSubscriptionID } from "../../LoginRole";
import { travelizeAuth } from "../../HeaderAuthentication";

export default function AddInvoiceModel(props) {
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [file, setFile] = useState("");

  const [fieldReq, setFieldReq] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  const [invoiceData, setInvoiceData] = useState({
    po_id: null,
    vendor_id: null,
    invoice_number: "",
    invoice_date: moment(new Date()).format("DD/MM/YYYY"),
    invoice_amount: "",
    invoice_status: "Pending",
    tax: "",
    unit_Total: "",
    grand_Total: "",
    subscriptionID: loginSubscriptionID,
  });

  const {
    invoice_id,
    po_id,
    vendor_id,
    invoice_number,
    invoice_date,
    invoice_amount,
    invoice_status,
    tax,
    unit_Total,
    grand_Total,
    subscriptionID,
  } = invoiceData;

  // FILE TTACHMENT
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
      }
    });
  };

  const fileToBase64 = (upldfile, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(upldfile);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  // console.log("FILE:", file);
  // FILE TTACHMENT

  const handleInputData = (e) => {
    const { name, value } = e.target;
    if (name === "vendor_id" || name === "po_id") {
      setInvoiceData({ ...invoiceData, [name]: +value });
    } else {
      setInvoiceData({ ...invoiceData, [name]: value });
    }
  };

  const handleSelectChange = (data, type) => {
    setInvoiceData({ ...invoiceData, [type]: data });
    switch (type) {
      case "invoice_date":
        setInvoiceDate(data);
        setInvoiceData({
          ...invoiceData,
          [type]: moment(data).format("DD/MM/YYYY"),
        });
        break;
      default:
    }
  };

  const addInvoiceDetails = (e) => {
    e.preventDefault();
    console.log("ADD-INV-DATA:", invoiceData);
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(invoiceData),
    };
    fetch(`${baseURL}/api/VHD/AddInvoices`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          props.fetchPODetails();
          closeModel();
        } else {
          toast.error(res.errorMessage, { autoClose: 5000 });
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
      });
  };

  const closeModel = () => {
    props.handleClose();
    props.setInvoiceID(null);
  };

  return (
    <div>
      <AddEditFormComponent
        handleClose={closeModel}
        formName={"Invoice"}
        formSubTitle={
          props.invoiceID
            ? "Edit and update the invoice details."
            : "Please enter the invoice details."
        }
        sidebarItems={["Invoice"]}
        childrens={
          <form>
            <div className="add-po_headers-to-vhd">
              <div className="add-po_headers-details">
                <div className="row">
                  <div className="col my-2">
                    <FloatingLabel
                      label="Invoice Date"
                      className={`${
                        invoiceDate
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <DatePicker
                        selected={invoiceDate}
                        autoComplete="off"
                        name="invoice_date"
                        className="form-control form add-input"
                        dateFormat="dd/MM/yyyy"
                        placeholder="Invoice Date"
                        onChange={(date) =>
                          handleSelectChange(date, "invoice_date")
                        }
                        // maxDate={new Date()}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col my-2">
                    <FloatingLabel
                      label="Invoice Number"
                      className={`${
                        invoice_number
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="text"
                        autoComplete="off"
                        name="invoice_number"
                        className="form-control form add-input"
                        placeholder="Invoice Number"
                        value={invoice_number}
                        onChange={(e) => handleInputData(e)}
                        required
                      />
                    </FloatingLabel>

                    {/* {fieldReq && vendorName == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )} */}
                  </div>
                  <div className="col my-2">
                    <FloatingLabel
                      label="PO Date"
                      className={`${
                        invoiceDate
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <DatePicker
                        selected={invoiceDate}
                        autoComplete="off"
                        name="invoice_date"
                        className="form-control form add-input"
                        dateFormat="dd/MM/yyyy"
                        placeholder="PO Date"
                        onChange={(date) =>
                          handleSelectChange(date, "invoice_date")
                        }
                        // maxDate={new Date()}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col my-2">
                    <FloatingLabel
                      label="PO Number"
                      className={`${
                        po_id
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        autoComplete="off"
                        name="po_id"
                        className="form-control form add-input"
                        placeholder="PO Number"
                        value={po_id}
                        onChange={(e) => handleInputData(e)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                </div>

                <div className="row">
                  <div className="col my-2">
                    <FloatingLabel
                      label="Vendor ID"
                      className={`${
                        vendor_id
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="text"
                        autoComplete="off"
                        name="vendor_id"
                        className="form-control form add-input"
                        placeholder="Vendor ID"
                        value={vendor_id}
                        onChange={(e) => handleInputData(e)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col my-2">
                    <FloatingLabel
                      label="Invoice Amount"
                      className={`${
                        invoice_amount
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        autoComplete="off"
                        name="invoice_amount"
                        className="form-control form add-input"
                        placeholder="Invoice Amount"
                        value={invoice_amount}
                        onChange={(e) => handleInputData(e)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col my-2">
                    <FloatingLabel
                      label="Tax Amount"
                      className={`${
                        tax ? "float-input-visible" : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        autoComplete="off"
                        name="tax"
                        className="form-control form add-input"
                        placeholder="Tax Amount"
                        value={tax}
                        onChange={(e) => handleInputData(e)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                  <div className="col my-2">
                    <FloatingLabel
                      label="Grand Total"
                      className={`${
                        grand_Total
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="number"
                        autoComplete="off"
                        name="grand_Total"
                        className="form-control form add-input"
                        placeholder="Grand Total"
                        value={grand_Total}
                        onChange={(e) => handleInputData(e)}
                        required
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>
              <div className="row dflex-j-between">
                <div className="add-page-save-cancel">
                  <SBSaveUpdateBtn
                    btnName="Save"
                    onClickEffect={addInvoiceDetails}
                  />
                  <SbCancelBtn btnName="Cancel" onClickEffect={closeModel} />
                </div>
              </div>
            </div>
          </form>
        }
      />
    </div>
  );
}
