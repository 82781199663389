import React, { useEffect, useLayoutEffect, useState, useRef } from "react";
import Footer from "../../Footer";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/VhdPODetails.scss";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { poDetailsTableData } from "../TestData";
import DataDisplayByColumn from "../DataDisplayByColumn";
import PODetailsTable from "./PODetailsTable";
import { MiCustomBtn } from "../../SbButtons/SbAddBtn";

export default function VhdPODetails() {
  const tableHeader = [
    { name: "Line No.", align: "left" },
    { name: "Item Name", align: "left" },
    { name: "Quantity", align: "left" },
    { name: "Discount %", align: "left" },
    { name: "Unit Rate", align: "left" },
    { name: "Line Total", align: "left" },
    { name: "Tax Code", align: "left" },
    { name: "Tax%", align: "left" },
    { name: "Tax Amount (₹)", align: "left" },
    { name: "Gross Total", align: "left" },
    { name: "UOM", align: "left" },
    { name: "Status", align: "center" },
  ];

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const [filterUserId, setFilterUserId] = useState("");
  const [activeStatus, setActiveStatus] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [file, setFile] = useState("");

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const currentPage = 1;
  //* PAGINATION END *//

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // FILE TTACHMENT
  const hiddenFileInput = useRef(null);

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setFile(result.toString().replace(/^data:(.*,)?/, ""));
      }
    });
  };

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };
  // FILE TTACHMENT

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setActiveStatus(1);
  };
  //* CLEAR SELECT END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // Page navigation

  return (
    <div>
      <>
        {/* SIDEBAR - TOPBAR - FOOTER  */}
        <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
        <Topbar
          toggleMenu={toggleMenu}
          menuCollapse={menuCollapse}
          toggleshift={toggleshift}
        />
        <Footer />
        {/* SIDEBAR - TOPBAR - FOOTER  */}
      </>
      <>
        <div className="page-wrapper">
          <div className={`main-body ${toggleshift.style}`}>
            <div className="page-content">
              {/* Filter and Header Section */}
              <div className="po-details-part">
                <Row>
                  <Col md={5} sm={12} className="cat-col">
                    <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToSettingPage}>
                        <img
                          src="../../../../images/DefaultImages/back-btn-arrow.svg"
                          alt=""
                        />
                      </div>
                      <h6 className="page-header-text">Purchase Order List</h6>
                    </div>
                  </Col>
                  <Col md={7} sm={12} className="cat-col">
                    <div className="dflex-j-end">
                      <div className="update-po-status">
                        <MiCustomBtn
                          btnName={"Cancel PO"}
                          btnColor={"secondary"}
                          // onClickEffect={cancelPo}
                        />
                      </div>
                      <div className="d-flex up-btn">
                        <MiCustomBtn
                          btnName={"Upload File"}
                          btnColor={"green"}
                          iconName={"fa fa-upload"}
                          onClickEffect={uploadClick}
                        />

                        <input
                          id="getFile"
                          type="file"
                          name="filetobase64"
                          accept="image/pdf"
                          className="btn-upload"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div>
                      <MiCustomBtn
                        btnName={"Save Invoice"}
                        btnColor={"primary"}
                        // onClickEffect={}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="card po-details-card">
                  <Row>
                    <Col md={6} sm={12} className="br-gray">
                      <DataDisplayByColumn
                        dataHead={"Po.DocEntry"}
                        dataBody={12728}
                      />
                      <DataDisplayByColumn
                        dataHead={"Doc.Noc"}
                        dataBody={101}
                      />
                      <DataDisplayByColumn
                        dataHead={"Date"}
                        dataBody={"27/08/2024"}
                      />
                      <DataDisplayByColumn
                        dataHead={"Vendor.Ref.No"}
                        dataBody={12728}
                      />
                      <DataDisplayByColumn
                        dataHead={"Ship.To"}
                        dataBody={
                          "1757/A, 34th Cross Banashankari 2nd Stage, Bangalore-560070 KT - IN"
                        }
                      />
                    </Col>
                    <Col md={6} sm={12} className="bl-gray">
                      <DataDisplayByColumn
                        dataHead={"PO Value"}
                        dataBody={3740}
                      />
                      <DataDisplayByColumn
                        dataHead={"Tax Amount(₹)"}
                        dataBody={170}
                      />
                      <DataDisplayByColumn
                        dataHead={"Doc.Total"}
                        dataBody={3910}
                      />
                      <DataDisplayByColumn
                        dataHead={"Branch"}
                        dataBody={"Karnataka"}
                      />
                      <DataDisplayByColumn
                        dataHead={"Bill.To"}
                        dataBody={
                          "No.1442, 21st cross, 5th main7th sector HSR layout Bengaluru-560102 KT - IN"
                        }
                      />
                    </Col>
                  </Row>
                </div>
                {/* TABLE START */}
                <div className="po-details-table-part">
                  <PODetailsTable
                    tableHeader={tableHeader}
                    tableData={poDetailsTableData}
                  />
                </div>
              </div>
              {/* TABLE END */}

              {/* PAGE CONTENT END*/}
            </div>
          </div>

          {/* MAIN BODY END */}
        </div>
      </>
    </div>
  );
}
